import { Box } from "@chakra-ui/react";
import React from "react";
import PvBanner from "../../components/PvBanner";
import Web3Platform from "./components/Web3Platform";
import AboutSlider from "./components/AboutSlider";
import SocialPlatforms from "./components/SocialPlatforms";
import ContactUs from "../../components/ContactUs";
import Team from "../../components/Team";

function AboutUs() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        header1="Polyverse: Uçtan Uca"
        header2="Web3 Geliştirme Hizmeti"
        buttonText="Dijital Dönüşümde Biz"
        boxPadding="57px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <Web3Platform />
      <AboutSlider />
      <Team />
      <SocialPlatforms />
      <ContactUs />
    </Box>
  );
}
export default AboutUs;
