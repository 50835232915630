import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  VStack,
  Text,
  Flex,
  Input,
  Textarea,
  Button,
  Icon,
  useColorModeValue,
  Circle,
  Spinner,
  Checkbox,
  Link,
} from "@chakra-ui/react";
import { GrLocation } from "react-icons/gr";
import { TbMail, TbPhone } from "react-icons/tb";

function BookAMeet() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(""); // Yeni state
  const [kvkk, setKvkk] = useState(false);

  const charLimit = 500;

  const bgGradient = useColorModeValue(
    "linear(to-b, #090c3210, #D9D9D926)",
    "linear(to-b, white, white)"
  );

  const gradientBorder = {
    border: "1px solid transparent",
    paddingY: "2",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(to right, #529BF0, #DB72EE)",
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError("Lütfen geçerli bir e-posta adresi girin.");
      return;
    }
    setEmailError("");
    setIsLoading(true);
    try {
      const emailToAdminData = new FormData();
      emailToAdminData.append("email", "team@polyverse.life");
      emailToAdminData.append(
        "subject",
        "polyverse life görüşme talebi alındı."
      );
      emailToAdminData.append(
        "body",
        `${name} adlı kişi polyverse life üzerinden görüşme talebinde bulundu. 
           Eklediği mesaj: ${message} 
           ve iletişim bilgileri: ${email}`
      );

      const smsToAdminData = new FormData();
      smsToAdminData.append("phone", "+905074118224");
      smsToAdminData.append(
        "message",
        `Yeni bir görüşme talebi alındı. İsim: ${name}, E-posta: ${email}`
      );

      //kullanıcıya mail göndermeyi bir süre askıda tutalım, mail templatei norbi olarak gidiyor
      //cüzdana git vb butonlar sorun yaratır
      //     const emailToUserData = new FormData();
      //     emailToUserData.append("email", email);
      //     emailToUserData.append(
      //       "subject",
      //       "Polyverse Life - Görüşme Talebiniz Alındı"
      //     );
      //     emailToUserData.append(
      //       "body",
      //       `Sayın ${name},

      // Mesajınızı aldık. En kısa sürede, en geç 48 saat içinde size dönüş yapacağız.

      // İletişime geçtiğiniz için teşekkür ederiz.

      // Saygılarımızla,
      // Polyverse Life Ekibi`
      //     );

      // const emailToUser = axios.post(
      //   "https://api.polyverse.life/System/Email",
      //   emailToUserData
      // );

      const emailToAdmin = axios.post(
        "https://api.polyverse.life/System/Email",
        emailToAdminData
      );

      const smsToAdmin = axios.post(
        "https://api.polyverse.life/System/Sms",
        smsToAdminData
      );

      await Promise.all([emailToAdmin, smsToAdmin]);

      console.log("All messages sent successfully");

      setName("");
      setEmail("");
      setMessage("");
      setIsSubmitted(true);
    } catch (error) {
      console.error("API Error:", error);
      setIsSubmitted(false);
    } finally {
      setIsLoading(false);
    }
  };

  const contactInfo = [
    {
      icon: GrLocation,
      label: "Adres",
      text: "Halaskargazi Cd. No: 38-66/E İç Kapı No:215, Nişantaşı Lotus, 34371 Şişli-İstanbul",
    },
    { icon: TbPhone, label: "Telefon", text: "0 (212) 939 9272" },
    { icon: TbMail, label: "Mail", text: "team@polyverse.life" },
  ];

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <VStack pt="180px" pb="20" textAlign="center" layerStyle="pvPadding">
        <Text
          fontSize={{ base: "24", sm: "28", lg: "30" }}
          fontWeight="semibold"
          color="blue.500"
        >
          Bizimle İletişime Geçin
        </Text>
        <Text
          fontSize={{
            base: "36px",
            sm: "40px",
            md: "48px",
            lg: "52px",
            xl: "60px",
          }}
          fontWeight="bold"
        >
          Geliştirici Kiti ile <br /> Web3 Uygulamalarınızı Güçlendirin
        </Text>
      </VStack>
      <Box
        width="100%"
        maxWidth="100vw"
        overflowX="hidden"
        bgGradient={bgGradient}
        minHeight="100vh"
        py={20}
        mb="20"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          width="100%"
          justify="space-between"
          align="flex-start"
          layerStyle="pvPadding"
          gap="80px"
        >
          <VStack align="flex-start" spacing={5} flex={1}>
            <Text fontSize="40" fontWeight="bold" color="black" opacity="0.8">
              Bizimle Projelerinizi Geleceğe Taşıyın
            </Text>
            <Text fontSize="24" color="black" opacity="0.9">
              Polyverse ile Web3 dünyasında güvenle ilerleyin. İnovasyon{" "}
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#2032EA",
                }}
              >
                bugün
              </span>{" "}
              bizimle başlayın!
            </Text>
            <VStack align="flex-start" spacing={6} pt="5" width="100%">
              {contactInfo.map((info, index) => (
                <Flex align="center" width="100%" key={index}>
                  <Circle
                    size="56px"
                    bg="white"
                    boxShadow="0 0 4px rgba(0, 0, 0, 0.25)"
                    mr={4}
                  >
                    <Icon as={info.icon} boxSize={6} color="black" />
                  </Circle>
                  <VStack align="flex-start" spacing={0}>
                    <Text fontWeight="bold" fontSize="lg">
                      {info.label}
                    </Text>
                    <Text>{info.text}</Text>
                  </VStack>
                </Flex>
              ))}
            </VStack>
          </VStack>

          <Box
            bg="white"
            borderRadius="12px"
            boxShadow="0 0px 50px rgba(0, 0, 0, 0.25)"
            p={{ base: 4, sm: 6, md: 8, lg: 10 }}
            width={{
              base: "full",
              sm: "full",
              md: "400px",
              lg: "500px",
              xl: "560px",
            }}
          >
            <VStack spacing={5}>
              <Input
                placeholder="Ad Soyad*"
                borderRadius="12px"
                sx={gradientBorder}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Flex direction="column" w="100%">
                <Input
                  placeholder="E-Posta*"
                  borderRadius="12px"
                  sx={gradientBorder}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => {
                    if (!validateEmail(email)) {
                      setEmailError("Lütfen geçerli bir e-posta adresi girin.");
                    } else {
                      setEmailError("");
                    }
                  }}
                />{" "}
                {emailError && (
                  <Text
                    fontSize={{ base: "12", xl: "14" }}
                    color="blue.500"
                    textAlign="stretch"
                  >
                    {emailError}
                  </Text>
                )}
              </Flex>
              <Box width="100%">
                <Textarea
                  height="232px"
                  borderRadius="16"
                  placeholder="Bize söylemek istedikleriniz...*"
                  resize="none"
                  sx={gradientBorder}
                  value={message}
                  onChange={(e) => {
                    if (e.target.value.length <= charLimit) {
                      setMessage(e.target.value);
                    }
                  }}
                />
                <Text textAlign="right" fontSize="sm" color="gray.500" mt="2">
                  {message.length}/{charLimit} karakter
                </Text>
              </Box>
              {/* <Flex align="center" justify="flex-start" width="100%">
                <Checkbox
                  colorScheme="blue"
                  size="md"
                  isChecked={kvkk}
                  onChange={(e) => setKvkk(e.target.checked)}
                >
                  <Text
                    fontSize={{ base: "14px", xl: "16px" }}
                    fontWeight=""
                    color="gray.500"
                  >
                    <Link
                      href="/aydinlatma-metni.pdf"
                      color="blue.500"
                      isExternal
                    >
                      KVKK aydınlatma metni
                    </Link>
                    ni okudum, anladım.
                  </Text>
                </Checkbox>
              </Flex> */}
              <Button
                variant="pvFulledGradient"
                color="text.secondary"
                width="200px"
                height="48px"
                onClick={handleSubmit}
                disabled={!name || !email || !message}
              >
                {isLoading ? <Spinner /> : "Bize Ulaşın"}
              </Button>

              {isSubmitted && (
                <Text
                  fontSize={{ base: "14", xl: "16" }}
                  color="blue.500"
                  textAlign="center"
                  mt={4}
                >
                  Formu gönderdiğiniz için teşekkür ederiz. Ekibimiz 48 saat
                  içinde yanıt verecektir.
                </Text>
              )}
            </VStack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default BookAMeet;
