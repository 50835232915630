import React from "react";
import Slider from "react-slick";
import { Box, Text, VStack, IconButton, Flex } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { TbDevicesCheck, TbDevicesCode, TbSettingsUp } from "react-icons/tb";
import { PiBrain, PiHandsClapping } from "react-icons/pi";
import { LuNetwork } from "react-icons/lu";

const features = [
  {
    icon: TbDevicesCheck,
    title: "Kod Yazmadan Geliştirme",
    description:
      "Kod yazmadan veya çok az kod yazarak (no-code & low-code) yazılım ihtiyaçlarını karşılayabilme",
  },
  {
    icon: TbDevicesCode,
    title: "Kolay Uygulama Geliştirme",
    description:
      "API ve SDK ile solidity bilgisi gerekmeden EVM destekli blok zinciri üzerinde kolayca uygulama geliştirebilme",
  },
  {
    icon: TbSettingsUp,
    title: "As-a-Service Model",
    description:
      'Geliştirilen ürün ve hizmetlerin "as-a-service" modeli ile diğer kurum ve kuruluşlarla kolayca paylaşılabilmesi',
  },
  {
    icon: PiHandsClapping,
    title: "Kullanıcı Deneyimi",
    description:
      "Son kullanıcıların Web 2.0'da alışık olduğu kullanıcı deneyimleriyle Web 2.5 çözümleri üreterek Web3'ün imkan ve kabiliyetlerini kullanabilmelerini sağlamak",
  },
  {
    icon: PiBrain,
    title: "Yapay Zeka Desteği",
    description:
      "Kişiye özel akıllı kontrat üretimi, akıllı kontrat ve NFT marketi, Web3 cüzdanı gibi ürünlerin platform sayesinde kolayca hazır hale getirilmesi",
  },
  {
    icon: LuNetwork,
    title: "Çoklu EVM Desteği",
    description:
      "Birden fazla blok zinciri ağı ile tüm ürün ve hizmetlerin çalışabilmesi",
  },
];

const GradientIcon = ({ IconComponent, ...props }) => {
  const isSpecialIcon = [
    TbDevicesCode,
    TbDevicesCheck,
    LuNetwork,
    TbSettingsUp,
  ].includes(IconComponent);

  return (
    <Box position="relative" width="52px" height="52px" {...props}>
      <IconComponent
        style={{
          width: "100%",
          height: "100%",
          fill: isSpecialIcon ? "none" : "url(#iconGradient)",
          stroke: "url(#iconGradient)",
          strokeWidth: isSpecialIcon ? 1.5 : 1, // Adjust stroke width for special icons
        }}
      />
      <svg width="0" height="0">
        <defs>
          <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop stopColor="#529BF0" offset="0%" />
            <stop stopColor="#DB72EE" offset="100%" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <IconButton
    aria-label="Previous"
    icon={<ArrowBackIcon color="blue.500" boxSize={6} />}
    onClick={onClick}
    size="lg"
    borderRadius="full"
    bg="rgba(217, 217, 217, 0.3)"
    color="white"
    _hover={{ bg: "rgba(217, 217, 217, 0.8)" }}
    mr="4"
  />
);

const CustomNextArrow = ({ onClick }) => (
  <IconButton
    aria-label="Next"
    icon={<ArrowForwardIcon color="blue.500" boxSize={6} />}
    onClick={onClick}
    size="lg"
    borderRadius="full"
    bg="rgba(217, 217, 217, 0.3)"
    color="white"
    _hover={{ bg: "rgba(217, 217, 217, 0.8)" }}
  />
);

const AboutSlider = () => {
  const sliderRef = React.useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    autoplay: true,
    centerMode: true,
    centerPadding: "70px",
    autoplaySpeed: 2000,
    pauseOnHover: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <Box
      py={{ base: 20, sm: 24 }}
      position="relative"
      className="slider-container"
    >
      <Slider {...settings} ref={sliderRef} className="overflow-slider">
        {features.map((feature, index) => (
          <Box key={index} px={{ base: 2, sm: 4, md: 5 }} py="2" width="400px">
            <Box
              borderRadius="20px"
              bg="rgba(217, 217, 217, 0.2)"
              h={{ base: "310px", sm: "250px", md: "270px", lg: "300px" }}
              display="flex"
              alignItems="flex-start"
              textAlign="start"
              p={{ base: 6, sm: 8 }}
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            >
              <VStack align="flex-start" spacing={2} w="100%">
                <GradientIcon IconComponent={feature.icon} />
                <Text
                  fontSize={{ base: "18px", md: "20px", lg: "22px" }}
                  fontWeight="bold"
                  whiteSpace="normal" // Metni sarmasını sağlar
                  overflow="hidden" // Taşan metni gizler
                  textOverflow="ellipsis" // Uzun metinlerde sonuna üç nokta ekler
                  w="100%"
                >
                  {feature.title}
                </Text>
                <Text fontSize={{ base: "14px", md: "16px", lg: "16px" }}>
                  {feature.description}
                </Text>
              </VStack>
            </Box>
          </Box>
        ))}
      </Slider>
      <Flex justifyContent="start" mt="4" layerStyle={"pvPadding"}>
        <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} />
        <CustomNextArrow onClick={() => sliderRef.current.slickNext()} />
      </Flex>
    </Box>
  );
};

export default AboutSlider;
