import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useBreakpointValue,
  useDisclosure,
  Image,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon, ChevronDownIcon } from "@chakra-ui/icons";
import Logo from "../assets/polyverse-logo.svg";
import { useNavigate } from "react-router-dom";
import { TbBuildingBank } from "react-icons/tb";
import { PiWallet, PiCoin } from "react-icons/pi";

const PolyLogo = ({ handleLogoClick }) => (
  <Box onClick={handleLogoClick} cursor="pointer">
    <Image src={Logo} alt="Poly Logo" width="auto" height="40px" />
  </Box>
);

const DesktopNav = ({ navItems }) => {
  return (
    <Stack direction={"row"} gap={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement="auto-end">
            <PopoverTrigger>
              <Flex
                as={Link}
                p={2}
                href={navItem.href}
                variant="ghost"
                color="text.secondary"
                fontSize="20px"
                width="auto"
                paddingX="4"
                borderRadius="8px"
                _hover={{
                  bgColor: "rgba(66, 153, 225, 0.2)",
                  paddingX: "4",
                  borderRadius: "10px",
                  textDecoration: "none",
                }}
                _active={{}}
                fontWeight="normal"
                alignItems="center"
              >
                <Text>{navItem.label}</Text>
              </Flex>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                rounded="10px"
                w={{ base: "90vw", md: "auto" }}
                maxW="90vw"
                bg="white"
                borderRadius="10px"
                borderColor="transparent"
                mt={8}
              >
                <Stack direction={{ base: "column", md: "row" }}>
                  <Box flex={1} pb={8} pt={6} pl={8}>
                    <Text fontWeight="bold" fontSize="26px" mb="2">
                      Platform
                    </Text>
                    <Stack w={["100%", "300px", "420px"]}>
                      {navItem.children
                        .filter((child) => child.description)
                        .map((child) => (
                          <DesktopSubNav key={child.title} {...child} />
                        ))}
                    </Stack>
                  </Box>
                  <Box
                    flex={1}
                    py={12}
                    pl={8}
                    roundedRight="10px"
                    bg="gray.100"
                  >
                    <Text
                      fontWeight="semibold"
                      fontSize="21px"
                      color="black"
                      mb={4}
                    >
                      Daha Fazla
                    </Text>
                    <Stack w={["100%", "150px", "350px"]}>
                      {navItem.children
                        .filter((child) => !child.description)
                        .map((child) => (
                          <DesktopSubNav key={child.title} {...child} isMore />
                        ))}
                    </Stack>
                  </Box>
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const labelIcons = {
  "Cüzdan Servis Hizmeti": PiWallet,
  Tokenizasyon: PiCoin,
  "Hazine Yönetimi": TbBuildingBank,
};

const DesktopSubNav = ({ title, href, description, isMore, tag }) => {
  const IconComponent = labelIcons[title];

  return (
    <Link
      href={href}
      _hover={{
        bgColor: !isMore ? "gray.100" : "none",
        borderRadius: !isMore ? "10px" : "none",
      }}
      role={"group"}
      display={"block"}
      py={isMore ? "0" : "2"}
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        align={{ base: "flex-start", md: "center" }}
      >
        <Flex align={"center"} pl={isMore ? 0 : { base: 2, md: 2 }}>
          {!isMore && IconComponent && (
            <Icon
              as={IconComponent}
              width="30px"
              height="30px"
              mr={4}
              display={{ base: "none", md: "block" }}
            />
          )}
          <Flex direction="column">
            <Text
              transition={"all .3s ease"}
              fontWeight="semibold"
              color="black"
              fontSize={{ base: "16px", md: "20px" }}
              _hover={{ textDecoration: isMore ? "underline" : "none" }}
            >
              {title}
            </Text>
            {description && (
              <Text
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="normal"
                color="#00000099"
              >
                {description}
              </Text>
            )}
          </Flex>
        </Flex>
        {tag && (
          <Button
            variant="pvGradient"
            alignSelf={{ base: "flex-start", md: "center" }}
            fontWeight="normal"
            px={6}
            py={2}
            mt={{ base: 2, md: 0 }}
            ml={{ base: 0, md: 4 }}
            color="blue.300"
            _hover={{ bgColor: "transparent" }}
            _active={{}}
            fontSize="12px"
            w="auto"
            height="auto"
            borderRadius="10px"
          >
            {tag}
          </Button>
        )}
      </Stack>
    </Link>
  );
};

const MobileNavItem = ({ label, children, href, tag, onClose }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={tag ? Text : Link}
        href={tag ? undefined : href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        onClick={() => {
          if (!children) onClose();
        }}
      >
        <Text fontWeight="semibold" color="white">
          {label}
        </Text>

        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            color="white"
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor="white"
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Flex key={child.title} align="center">
                <Link
                  href={child.href}
                  py={2}
                  color="white"
                  _hover={{
                    textDecoration: "none",
                  }}
                  onClick={onClose}
                >
                  {child.title}
                </Link>
                {child.tag && (
                  <Button
                    variant="pvGradient"
                    alignSelf="center"
                    fontWeight="normal"
                    px={4}
                    py={2}
                    ml={4}
                    color="white"
                    _hover={{}}
                    _active={{}}
                    fontSize="12px"
                    w="auto"
                    height="auto"
                    borderRadius="10px"
                  >
                    {child.tag}
                  </Button>
                )}
              </Flex>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
const MobileNav = ({ navItems, isOpen, onClose }) => {
  return (
    <Stack align="stretch" spacing={4}>
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
      ))}
    </Stack>
  );
};

const Header = ({ navItems }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Box
      as="header"
      width="full"
      zIndex="sticky"
      position="fixed"
      py={{ base: 4, md: 6 }}
      bg="purple.500"
      layerStyle="pvPadding"
    >
      <Flex justify="space-between" align="center">
        <PolyLogo handleLogoClick={handleLogoClick} />

        {isDesktop ? (
          <>
            <DesktopNav navItems={navItems} />
            <Button
              variant="pvFulledGradient"
              fontSize="16px"
              fontWeight="bold"
              color={"white"}
              onClick={() => {
                navigate("/book-a-meet");
              }}
            >
              Bize Ulaşın
            </Button>
          </>
        ) : (
          <IconButton
            onClick={onOpen}
            icon={<HamburgerIcon w={5} h={5} color="white" />}
            variant={"ghost"}
            _hover={{ bg: "transparent" }}
            _active={{}}
            aria-label={"Toggle Navigation"}
          />
        )}
      </Flex>

      {!isDesktop && (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerContent bg="purple.500">
            <DrawerCloseButton color="text.secondary" />
            <DrawerBody py={20}>
              <MobileNav
                navItems={navItems}
                isOpen={isOpen}
                onClose={onClose}
              />
              <Button
                variant="pvFulledGradient"
                fontSize="16px"
                fontWeight="bold"
                color={"white"}
                onClick={() => {
                  navigate("/book-a-meet");
                  onClose();
                }}
                mt={4}
              >
                Bize Ulaşın
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default Header;
