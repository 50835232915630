import React from "react";
import PvBanner from "../components/PvBanner";
import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import QAAccordion from "../components/QAAccordion";
import ContactUs from "../components/ContactUs";
import { AiOutlineApi } from "react-icons/ai";
import { LuLayers } from "react-icons/lu";
import { VscTools } from "react-icons/vsc";
import { HiOutlineDocumentText } from "react-icons/hi";
import { GrGroup } from "react-icons/gr";
import { PiHandsClapping } from "react-icons/pi";
import Image1 from "../assets/image-1.png";
import Image2 from "../assets/image-2.png";
import { keyframes } from "@emotion/react";

const features = [
  {
    title: "Kapsamlı API'ler",
    description:
      "Geliştiricilere sunduğumuz API'ler ile projelerinizi kolayca entegre edin ve genişletin",
    icon: AiOutlineApi,
  },
  {
    title: "Esnek SDK'lar",
    description:
      "Çeşitli programlama dilleri ve platformlar için esnek SDK'lar",
    icon: LuLayers,
  },
  {
    title: "Güçlü Araçlar",
    description:
      "Hata ayıklama, test etme ve izleme araçları ile geliştirme sürecinizi kolaylaştırın",
    icon: VscTools,
  },
  {
    title: "Detaylı Dokümantasyon",
    description:
      "Adım adım kılavuzlar ve detaylı dokümantasyon ile hızlı başlangıç yapın",
    icon: HiOutlineDocumentText,
  },
  {
    title: "Topluluk Desteği",
    description:
      "Aktif geliştirici topluluğu ve forumlar ile sorularınıza hızlı yanıt bulun",
    icon: GrGroup,
  },
  {
    title: "Kullanıcı Dostu Arayüz",
    description:
      "Web3 uygulamaları için benzersiz ve kullanıcı dostu arayüzler oluşturma olanağı",
    icon: PiHandsClapping,
  },
];

const qaData = [
  {
    question: "Geliştirici Kiti nedir?",
    answer:
      "Geliştirici Kiti (Dev Kit), projelerinizi hızlıca geliştirmek ve entegre etmek için kapsamlı araçlar ve API'ler sunar",
  },
  {
    question: "Geliştirici Kitini nasıl kullanmaya başlayabilirim?",
    answer: "Detaylı dokümantasyonu inceleyerek başlayabilirsiniz",
  },
  {
    question: "Hangi programlama dillerini destekliyorsunuz?",
    answer:
      "Birçok popüler programlama dili ve platform için SDK'lar ve API'ler sunuyoruz",
  },

  {
    question: "Geliştirici Kitinin sunduğu en önemli özellikler nelerdir?",
    answer:
      "Geliştirici Kiti, yüksek ölçeklenebilirlik, güvenlik, kullanıcı dostu arayüzler ve çoklu platform desteği gibi önemli özellikler sunar. Ayrıca, hızlı entegrasyon ve kolay kullanım için kapsamlı örnek kodlar ve rehberler içerir",
  },
];

const devkitUseCases = [
  {
    title: "Web Uygulamaları",
    description:
      "Web projelerinizi geliştirmek ve hızla entegre etmek için kapsamlı araçlar",
  },
  {
    title: "Mobil Uygulamalar",
    description: "Mobil projeleriniz için esnek SDK'lar ve API'ler",
  },
  {
    title: "Blok Zinciri Projeleri",
    description:
      "Blok zinciri tabanlı projeler için özel olarak tasarlanmış araçlar ve API'ler",
  },
  {
    title: "Fintech Çözümleri",
    description:
      "Finansal teknoloji projeleri için güvenli ve ölçeklenebilir çözümler",
  },
  {
    title: "E-Ticaret",
    description:
      "E-Ticaret projeleriniz için entegre ödeme çözümleri ve daha fazlası",
  },
  {
    title: "Oyunlar",
    description:
      "Oyun geliştiricilerin, oyun severlere kolayca Web3 deneyimi sunabilmesi imkanı",
  },
];

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

function Developers() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const handleSecondButtonClick = () => {
    // Handle second button click
    window.open("https://platform.polyverse.life", "_blank");
  };

  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Geliştiricilere sunduğumuz kapsamlı araçlar ile Web3 dünyasına eşsiz bir giriş yapın"
        header1="Web3 Uzmanı Olmadan"
        header2="Projelerinizi Güçlendirin"
        buttonText="Geliştirici Kiti"
        boxPadding="58px"
        secondButtonText="Şimdi Başlayın 🚀" // New prop for the second button
        onSecondButtonClick={handleSecondButtonClick}
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <PvFeatureGrid items={features} />
      <Box layerStyle="pvPadding" mt={24}>
        <Text
          fontSize={{ base: "24", sm: "28", lg: "30" }}
          fontWeight="semibold"
          textAlign="center"
          mb={10}
          color="blue.500"
        >
          Web3 Projesi Yaratmaya Bir Adım Uzaklıktasınız
        </Text>
        <Text
          fontSize={{ base: "18px", md: "22px", lg: "28px" }}
          fontWeight="normal"
          opacity={0.9}
          color="#000000"
          textAlign="center"
        >
          Projelerinizi sorunsuz bir şekilde yönetin, zengin akıllı sözleşme
          koleksiyonumuzla hemen başlayın ve ilham verici şablonlarımızla kendi
          Web3 uygulamalarınızı geliştirin{" "}
        </Text>
        <Flex
          justify="center"
          mt={16}
          gap={10}
          direction={isMobile ? "column" : "row"}
        >
          <Box
            position="relative"
            width="100%"
            height="100%"
            borderRadius="21"
            bgGradient="linear(180deg, #E6E5E6, #DB72EE, #E6E5E6)"
            backgroundSize="200% 200%"
            border="1px solid transparent" // Borderı tanımlıyoruz.
            _hover={{
              animation: `${gradientAnimation} 3s ease infinite`,
            }}
          >
            <Box
              position="relative"
              borderRadius="20"
              bgGradient="linear(180deg, #E6E5E6, #DB72EE, #E6E5E6)"
              width="100%"
              height="100%"
              overflow="hidden"
              display="flex"
              flexDirection="column"
            >
              <Image
                src={Image1}
                w={"auto"}
                h={"auto"}
                borderRadius="21"
                fit="cover"
              />
            </Box>
          </Box>
          <Box
            position="relative"
            width="100%"
            height="100%"
            borderRadius="21"
            bgGradient="linear(180deg, #E6E5E6, #DB72EE, #E6E5E6)"
            backgroundSize="200% 200%"
            border="1px solid transparent" // Borderı tanımlıyoruz.
            _hover={{
              animation: `${gradientAnimation} 3s ease infinite`,
            }}
          >
            <Box
              position="relative"
              borderRadius="20"
              bgGradient="linear(180deg, #E6E5E6, #DB72EE, #E6E5E6)"
              width="100%"
              height="100%"
              overflow="hidden"
              display="flex"
              flexDirection="column"
            >
              <Image
                src={Image2}
                w={"auto"}
                h={"auto"}
                borderRadius="21"
                fit="cover"
              />
            </Box>
          </Box>
        </Flex>
      </Box>
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={devkitUseCases}
      />
      <QAAccordion faqData={qaData} />
      <ContactUs />
    </Box>
  );
}

export default Developers;
