import React, { useEffect, useState } from "react";
import { Box, Text, HStack, VStack } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import {
  PiArrowElbowLeftBold,
  PiHandsClapping,
  PiShieldCheckeredFill,
} from "react-icons/pi";
import { RiRocketLine } from "react-icons/ri";

const MotionBox = motion(Box);

const features = [
  { text: "Kolay ve Esnek", icon: PiArrowElbowLeftBold },
  { text: "Kullanıcı Dostu", icon: PiHandsClapping },
  { text: "Yüksek Güvenlikli", icon: PiShieldCheckeredFill },
  { text: "Akıllı Çözüm", icon: MdOutlineTipsAndUpdates },
  { text: "Yenilikçi Yaklaşım", icon: RiRocketLine },
];

const GradientIcon = ({ IconComponent, ...props }) => {
  return (
    <Box
      position="relative"
      width={{ base: "40px", md: "44px", lg: "52px" }}
      height={{ base: "40px", md: "44px", lg: "52px" }}
      {...props}
    >
      <IconComponent
        style={{
          width: "100%",
          height: "100%",
          fill: "url(#iconGradient)",
          stroke: "url(#iconGradient)",
        }}
      />
      <svg width="0" height="0">
        <defs>
          <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop stopColor="#529BF0" offset="0%" />
            <stop stopColor="#DB72EE" offset="100%" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

const SliderBox = () => {
  const controls = useAnimation();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const calculateWidth = () => {
      let boxWidth;
      if (window.innerWidth >= 992) {
        // lg breakpoint
        boxWidth = 292;
      } else if (window.innerWidth >= 768) {
        // md breakpoint
        boxWidth = 266;
      } else {
        // base
        boxWidth = 222;
      }
      const gap = 30;
      return features.length * (boxWidth + gap);
    };

    const handleResize = () => {
      setWidth(calculateWidth());
    };

    handleResize(); // Initial calculation

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width > 0) {
      controls.start({
        x: [`0px`, `-${width}px`],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 30,
            ease: "linear",
          },
        },
      });
    }
  }, [controls, width]);

  return (
    <VStack pt="20" align="center" textAlign="center">
      <Text textStyle="pvHeader" layerStyle="pvPadding">
        Polyverse: Uçtan Uca
        <br /> Web3 Geliştirme Hizmeti
      </Text>
      <Text
        textAlign="center"
        pt="4"
        fontSize={{ base: "18px", md: "22px", lg: "28px" }}
        fontWeight="normal"
        opacity={0.9}
        layerStyle="pvPadding"
      >
        Polyverse, merkeziyetsiz uygulama oluşturma ve geliştirme sürecini kod
        yazmadan veya çok az kod yazarak (no-code & low-code) kolaylaştırmayı
        amaçlayan kapsamlı bir Web3 geliştirme hizmeti sağlar
      </Text>
      .
      <Box
        overflow="hidden"
        w="100%"
        pt={{ base: 12, lg: 20 }}
        pb={{ base: 16, sm: 24, lg: 28 }}
      >
        <HStack
          as={motion.div}
          animate={controls}
          spacing={8}
          position="relative"
          width={`${width * 2}px`}
        >
          {[...features, ...features].map((feature, index) => (
            <MotionBox
              key={index}
              w={{ base: "222px", md: "266px", lg: "292px" }}
              h={{ base: "152px", md: "182px", lg: "200px" }}
              borderRadius="20px"
              bg="rgba(217, 217, 217, 0.2)"
              display="flex"
              flexDirection="column"
              alignItems="start"
              justifyContent="center"
              p="8"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
              flexShrink={0}
            >
              <GradientIcon IconComponent={feature.icon} mb={4} />
              <Text
                fontSize={{ base: "18px", md: "22px", lg: "28px" }}
                fontWeight="bold"
                textAlign="center"
              >
                {feature.text}
              </Text>
            </MotionBox>
          ))}
        </HStack>
      </Box>
    </VStack>
  );
};

export default SliderBox;
