import React from "react";
import { Box } from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import {
  PiPuzzlePieceBold,
  PiShieldCheckeredFill,
  PiLinkBold,
  PiArrowElbowLeftBold,
  PiWalletBold,
} from "react-icons/pi";
import PvBanner from "../components/PvBanner";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import QAAccordion from "../components/QAAccordion";
import ContactUs from "../components/ContactUs";

const features = [
  {
    title: "Anında Transferler",
    description:
      "Cüzdan adresinin yanı sıra, mail ve telefon numarası ile varlık transferi",
    icon: FiSend,
  },
  {
    title: "Kolay Entegrasyon",
    description: "API'ler ve SDK'lar ile hızlı ve kolay entegrasyon",
    icon: PiPuzzlePieceBold,
  },
  {
    title: "Çoklu Zincir (MultiChain) Desteği",
    description: "Çok çeşitli blok zincir ağları için kapsamlı destek",
    icon: PiLinkBold,
  },
  {
    title: "Esnek Özelleştirme",
    description:
      "Hizmeti kendi marka kimliğinize göre White Label çözümü ile özelleştirin",
    icon: PiArrowElbowLeftBold,
  },
  {
    title: "Güvenlik Standartları",
    description:
      "İki faktörlü kimlik doğrulama, biyometrik doğrulama ve şifreleme ile varlıklarınız güvende",
    icon: PiShieldCheckeredFill,
  },
  {
    title: "Anında Cüzdan",
    description:
      "Sadece mail veya telefon numarası ile anlık cüzdan oluşturabilme",
    icon: PiWalletBold,
  },
];

const devkitUseCases = [
  {
    title: "Bireysel Kullanıcılar",
    description:
      "Kendi dijital varlıklarını güvenli bir şekilde saklamak ve yönetmek isteyen bireysel kullanıcılar için idealdir",
  },
  {
    title: "E-Ticaret ve Perakende",
    description:
      "Dijital varlıklar ile e-ticaret ve perakende işletmeleri, müşterilerine tokenize edilmiş varlıklar ile sadakat sistemlerine alternatif ürünler sunabilir",
  },
  {
    title: "Finansal Hizmetler",
    description:
      "Bankalar ve finansal hizmet sağlayıcıları, müşterilerine dijital cüzdan hizmetleri sunarak, kripto para işlemlerini destekleyebilirler",
  },
  {
    title: "Kurumsal Şirketler",
    description:
      "Dijital varlıkları yönetmek ve uluslararası işlemler yapmak isteyen kurumsal şirketler için uygundur",
  },
  {
    title: "Start-Up ve FinTech Şirketleri",
    description:
      "Yenilikçi çözümler sunmak isteyen start-up ve fintech şirketleri için ideal bir platformdur",
  },
];

const waasData = [
  {
    question: "Cüzdan Servis Hizmeti (WaaS) nedir?",
    answer:
      "Cüzdan Servis Hizmeti, güvenli depolama, kullanıcı dostu arayüz, hızlı işlem imkanı, çoklu varlık desteği ve entegrasyon kolaylığı gibi avantajlar sunar. Bu sayede dijital varlıklarınızı yönetmek hem güvenli hem de kolay hale gelir",
  },
  {
    question: "Hangi blok zinciri ağlarını destekliyor?",
    answer:
      "Cüzdan Servis Hizmeti, birden fazla blok zincir ağını destekleyerek (EVM) kripto paralar ve diğer dijital varlıklar arasında kolayca geçiş yapmanıza olanak tanır",
  },
  {
    question: "Cüzdan Servis Hizmeti (WaaS) nasıl çalışır?",
    answer:
      "Cüzdan Servis Hizmeti, API'ler ve SDK'lar aracılığıyla entegrasyon sağlayarak Web3 cüzdan ihtiyacınızı karşılar",
  },
  {
    question: "Cüzdan Servis Hizmetinin avantajları nelerdir?",
    answer:
      "Cüzdan Servis Hizmeti, güvenli depolama, kullanıcı dostu arayüz, hızlı işlem imkanı, çoklu varlık desteği ve entegrasyon kolaylığı gibi avantajlar sunar. Bu sayede dijital varlıklarınızı yönetmek hem güvenli hem de kolay hale gelir",
  },
];

function WalletAsService() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Yüksek güvenlik standartları ve kolay entegrasyon imkanları ile kullanıcılarınıza dijital cüzdanlar sunun ve varlıklarını güvenle yönetmelerini sağlayın"
        header1="En Kolay "
        header2="Dijital Cüzdan Çözümü"
        buttonText="Cüzdan Servis Hizmeti (Wallet as a Service)"
        boxPadding="35px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <PvFeatureGrid items={features} />
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={devkitUseCases}
      />
      <QAAccordion faqData={waasData} />
      <ContactUs />
    </Box>
  );
}

export default WalletAsService;
