import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: '"Hanken Grotesk", sans-serif',
    heading: '"Hanken Grotesk", sans-serif',
  },
  fontWeights: { normal: 400, semibold: 600, bold: 700 },
  colors: {
    background: "white",
    primary: {
      gradient: "linear-gradient(to right, #529BF0, #DB72ED 88%)",
    },
    text: {
      primary: "#000000",
      secondary: "#ffffff",
      tertiary: "rgba(0, 0, 0, 0.5)",
    },
    black: "#000000",
    white: {
      default: "#FFFFFF",
      26: "#FFFFFF26",
      99: "#FFFFFF99",
      80: "#FFFFFF80",
    },
    blue: {
      300: "#529BF0",
      500: "#2032EA",
    },
    gray: {
      100: "#D9D9D9",
      200: "#888888",
      500: "#333333",
    },
    purple: {
      300: "#DB72ED",
      500: "#090C32",
    },
  },
  textStyles: {
    h1: {
      fontSize: ["24px", "36px", "48px"],
      fontWeight: "bold",
      lineHeight: "110%",
    },
    body: {
      fontSize: ["14px", "16px"],
      fontWeight: "normal",
    },
    pvHeader: {
      fontWeight: "bold",
      fontSize: {
        base: "36px",
        sm: "40px",
        md: "48px",
        lg: "52px",
        xl: "60px",
      },
    },
  },
  styles: {
    global: {
      body: {
        bg: "background",
        color: "text.primary",
      },
    },
  },
  layerStyles: {
    pvPadding: {
      px: {
        base: 6,
        sm: 12,
        md: 16,
        lg: 24,
        xl: "150px",
      },
    },
  },
  components: {
    Button: {
      variants: {
        pvGradient: {
          bg: "transparent",
          borderRadius: "8px",
          isolation: "isolate",
          overflow: "hidden",
          width: "200px",
          height: "48px",
          position: "relative",
          textTransform: "none",
          fontWeight: "bold",
          transition: "color 0.3s, opacity 0.3s",
          color: "black", // Set default text color to black
          _before: {
            content: '""',
            position: "absolute",
            inset: "0",
            borderRadius: "inherit",
            padding: "2px", // Border width
            background: "linear-gradient(to right, #529BF0, #9256E7)",
            WebkitMask:
              "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            maskComposite: "exclude",
            zIndex: -1,
          },
          _after: {
            content: '""',
            position: "absolute",
            inset: "0",
            borderRadius: "inherit",
            background: "linear-gradient(to right, #529BF0, #DB72EE)",
            opacity: 0,
            transition: "opacity 0.3s",
            zIndex: -1,
          },
          _hover: {
            color: "white", // Change text color to white on hover
            _after: {
              opacity: 1,
            },
          },
        },
        pvFulledGradient: {
          bg: "linear-gradient(to right, #529BF0, #DB72EE)",
          borderRadius: "6px",
          width: "150px",
          height: "40px",
          textTransform: "none",
          fontWeight: "bold",
          position: "relative",
          overflow: "hidden",
          color: "text.secondary",
          _hover: {
            "::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to right, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 30%)",
              filter: "blur(3px)",
              animation: "shine 0.6s forwards",
            },
          },
          "&:disabled": {
            opacity: 0.6,
            pointerEvents: "none",
          },
          "@keyframes shine": {
            "0%": { transform: "translateX(-100%)" },
            "100%": { transform: "translateX(100%)" },
          },
        },
      },
    },
  },
});

export default theme;
