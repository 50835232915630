const navItems = [
  {
    label: "Platform",
    children: [
      {
        title: "Cüzdan Servis Hizmeti",
        description: "Dijital cüzdan ihtiyacı için en iyi çözüm",
        href: "/wallet-as-a-service",
      },
      {
        title: "Tokenizasyon",
        description: "Varlıkların dijital tokenlara dönüştürülmesi",
        href: "/tokenization",
      },
      //   {
      //     title: "DevKit",
      //     description: "Geliştiricilere özel Web3 araç ve kütüphaneleri",
      //     href: "/devkit",
      //   },
      {
        title: "Hazine Yönetimi",
        description: "Dijital varlıkların etkin yönetimi",
        // href: "/treasury-management",
        tag: "Çok Yakında!",
      },

      {
        title: "Merkeziyetsiz Finans (DeFi) Erişimi ",
        href: "/defi-access",
      },
      {
        title: "Web3 Erişimi",
        href: "/web3-access",
      },
      {
        title: "Depolama Hizmeti",
        href: "/storage",
      },
      {
        title: "Yapay Zeka",
        href: "/ai",
      },
    ],
  },

  { label: "Geliştiriciler", href: "/developers" },
  { label: "Hakkımızda", href: "/about-us" },
];

export default navItems;
