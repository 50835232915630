import React, { useState } from "react";
import {
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Grid,
  GridItem,
  useBreakpointValue,
} from "@chakra-ui/react";

const QAAccordion = ({ faqData }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const splitFaqData = (data) => {
    const mid = Math.ceil(data.length / 2);
    return [data.slice(0, mid), data.slice(mid)];
  };

  const [leftColumn, rightColumn] = splitFaqData(faqData);

  const baseBg = useBreakpointValue({
    base: `
      linear-gradient(135deg, #DEDEDE 0%, #DEDEDE 20%, transparent 51%),
      linear-gradient(315deg, #DEDEDE 0%, #DEDEDE 20%, transparent 51%),
      radial-gradient(circle at top right, #DB72EE, transparent 70%),
      radial-gradient(circle at bottom left, #DB72EE, transparent 70%)
    `,
    md: "#DEDEDE",
  });

  return (
    <Box bg="transparent" layerStyle="pvPadding">
      <Text
        fontWeight="semibold"
        color="blue.500"
        textAlign="center"
        fontSize={{ base: "24", sm: "28", lg: "30" }}
        pb={{ base: 4, sm: 8, md: 10 }}
      >
        Merak Ettikleriniz
      </Text>

      <Accordion allowToggle pb={{ base: 12, sm: 16, md: 24 }}>
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          gap={{ base: 0, md: 8 }}
        >
          {[leftColumn, rightColumn].map((column, columnIndex) => (
            <GridItem key={columnIndex}>
              {column.map((item, index) => (
                <Box
                  key={index}
                  position="relative"
                  mb={4}
                  borderRadius="21px"
                  overflow="hidden"
                  bg={baseBg}
                  p="1px"
                  onMouseEnter={() => !isMobile && setIsHovered(true)}
                  onMouseLeave={() => !isMobile && setIsHovered(false)}
                  _hover={
                    isMobile
                      ? {}
                      : {
                          "&::before": {
                            transform: "scale(1.5)",
                            opacity: 1,
                          },
                        }
                  }
                  sx={
                    isMobile
                      ? {}
                      : {
                          "&::before": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background:
                              "radial-gradient(circle at var(--mouse-x) var(--mouse-y), #DB72EE, transparent 60%)",
                            transition: "transform 0.3s, opacity 0.3s",
                            pointerEvents: "none",
                            opacity: 0,
                          },
                        }
                  }
                  onMouseMove={
                    isMobile
                      ? undefined
                      : (e) => {
                          const rect = e.currentTarget.getBoundingClientRect();
                          e.currentTarget.style.setProperty(
                            "--mouse-x",
                            `${e.clientX - rect.left}px`
                          );
                          e.currentTarget.style.setProperty(
                            "--mouse-y",
                            `${e.clientY - rect.top}px`
                          );
                        }
                  }
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    position="relative"
                    borderRadius="20px"
                    overflow="hidden"
                    bg="#F7F7F7"
                    flex="1"
                    display="flex"
                    flexDirection="column"
                  >
                    <AccordionItem
                      border="none"
                      bg="#F7F7F7"
                      borderRadius="20px"
                      position="relative"
                      zIndex={1}
                      p={4}
                    >
                      <AccordionButton _hover={{ bg: "transparent" }}>
                        <Box
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          fontSize={{ base: "20px", sm: "22px", md: "24px" }}
                        >
                          {item.question}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel px={8} py={4}>
                        {item.answer.split("\n").map((line, i) => (
                          <Text
                            key={i}
                            mb={2}
                            fontSize={{ base: "16px", sm: "18px", md: "20px" }}
                            dangerouslySetInnerHTML={{ __html: line }}
                          />
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  </Box>
                </Box>
              ))}
            </GridItem>
          ))}
        </Grid>
      </Accordion>
    </Box>
  );
};

export default QAAccordion;
