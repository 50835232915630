import React from "react";
import PvBanner from "../components/PvBanner";
import { Box, Text, Image } from "@chakra-ui/react";
import SliderBox from "../components/SliderBox";
import ServiceGrid from "../components/ServiceGrid";
import Stats from "../components/Stats";
import ContactUs from "../components/ContactUs";
import PolygonImage from "../assets/polygon-image.png";

const Home = () => {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        header1="Herkes için"
        header2="Web3’ü Kolaylaştırır"
        buttonText="Polyverse’e Hoşgeldiniz"
        boxPadding="42px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <SliderBox />
      <Text
        pt={{
          base: 4,
          sm: 12,
          md: 12,
          lg: 20,
          xl: 30,
        }}
        pb={{
          base: 2,
          sm: 4,
          md: 5,
        }}
        color="blue.500"
        textAlign="center"
        fontWeight="bold"
        fontSize={{
          base: "24px",
          md: "26px",
          xl: "30px",
        }}
      >
        Polyverse Yolculuğu
      </Text>
      <Text
        textStyle="pvHeader"
        textAlign="center"
        pb={{
          base: 8,
          sm: 12,
          md: "60px",
        }}
      >
        Sunduğumuz imkanları keşfedin
      </Text>
      <ServiceGrid />
      <Stats />
      <Box mt={{ base: 12, md: 16, lg: 20 }} mb={{ base: 12, md: 16, lg: 20 }}>
        <Image src={PolygonImage} width="100%" objectFit="cover" />
      </Box>
      <ContactUs />
    </Box>
  );
};

export default Home;
