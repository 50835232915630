import React from "react";
import {
  SimpleGrid,
  Container,
  Text,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import GradientCard from "../../../components/GradientCard";

const MissionItem = ({ title, description }) => (
  <ListItem
    fontSize={{
      base: "16px",
      sm: "18px",
      lg: "20px",
    }}
  >
    <Text as="span" fontWeight="semibold">
      {title}
    </Text>{" "}
    <Text as="span" fontWeight="regular">
      {description}
    </Text>
  </ListItem>
);

const Web3Platform = () => {
  const missionDescription = (
    <UnorderedList spacing={6} pl={4}>
      <MissionItem
        title="Web3 Kullanımını Kolaylaştırmak:"
        description="Kullanıcı dostu araçlar ve çözümler ile Web3 kullanımı basitleşir"
      />
      <MissionItem
        title="Zaman Kazandırmak:"
        description="Hazır çözümler, Web3 projeleri geliştirme süresini önemli ölçüde azaltır"
      />
      <MissionItem
        title="Maliyetleri Düşürmek:"
        description="Modüler ve as-a-service model Web3 proje maliyetlerini minimize eder"
      />
    </UnorderedList>
  );

  return (
    <Container
      maxW="container.xl"
      pt={{ base: 16, md: 32 }}
      px={{
        base: 4,
        sm: 8,
        md: 12,
        lg: 16,
      }}
    >
      <Text
        fontSize={{
          base: "36px",
          sm: "40px",
          md: "48px",
          lg: "52px",
          xl: "60px",
        }}
        fontWeight="bold"
        textAlign="center"
        mb={4}
      >
        Web3’ü Herkes için Kolaylaştırıyoruz
      </Text>
      <Text
        textAlign="center"
        fontSize={{
          base: "22px",
          sm: "26px",
          lg: "30px",
          xl: "32px",
        }}
        mb={{ base: 16, md: 28 }}
      >
        Geliştiricilerden işletmelere kadar herkese blok zinciri kodlaması
        yapmadan Web3 ve dApp projeleri oluşturma imkanı sunarak inovasyon ve
        verimlilik sağlıyoruz
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={16}>
        <GradientCard
          title="Vizyonumuz"
          description="Web3 teknolojilerini herkes için erişilebilir ve kolay hale getirerek, merkeziyetsiz uygulamaların benimsenmesini hızlandırmak ve blok zincir teknolojisinin potansiyelini tüm sektörlerde ortaya çıkarmak"
        />
        <GradientCard title="Misyonumuz" description={missionDescription} />
      </SimpleGrid>
    </Container>
  );
};

export default Web3Platform;
