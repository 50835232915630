import { Box, Text, VStack } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const GradientCard = ({ title, description }) => (
  <Box
    position="relative"
    width="100%"
    height="100%"
    minH="220px"
    p="1px"
    borderRadius="21"
    bgGradient="linear(180deg, #E6E5E6, #DB72EE, #E6E5E6)"
    backgroundSize="200% 200%"
    _hover={{
      animation: `${gradientAnimation} 3s ease infinite`,
    }}
  >
    <Box
      position="relative"
      borderRadius="20"
      bgGradient="linear(180deg, #F7F7F7 50%, #F9F3FB 79%, #FEFCFF 100%)"
      p="9"
      width="100%"
      height="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <VStack
        align="start"
        spacing={5}
        position="relative"
        zIndex="2"
        height="100%"
        justify="space-between"
      >
        <Text fontSize={{ base: "22", sm: "24", lg: "26" }} fontWeight="bold">
          {title}
        </Text>
        <Text fontSize={{ base: "16", sm: "18", lg: "20" }} flexGrow={1}>
          {description}
        </Text>
      </VStack>
    </Box>
  </Box>
);

export default GradientCard;
