import React from "react";
import PvBanner from "../components/PvBanner";
import { Box } from "@chakra-ui/react";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import QAAccordion from "../components/QAAccordion";
import ContactUs from "../components/ContactUs";
import { PiArrowElbowLeftBold, PiShieldCheckeredFill } from "react-icons/pi";
import { TiFlashOutline } from "react-icons/ti";
import { TbMoneybag } from "react-icons/tb";
import { RiCurrencyLine } from "react-icons/ri";
import { MdQuestionMark } from "react-icons/md";

const features = [
  {
    title: "Güvenli Tokenizasyon",
    description:
      "Varlıklarınızı güvenlik standartları ile dijital tokenlara dönüştürün",
    icon: PiShieldCheckeredFill,
  },
  {
    title: "Çok Çeşitli Finansal Enstrümanlar",
    description:
      "Devlet tahvilleri ve bonolar, hisse senetleri gibi birçok finansal varlığı tokenize edin",
    icon: TbMoneybag,
  },
  {
    title: "İkincil Pazar Erişimi",
    description: "Tokenlarınızın ikincil piyasalarda ticaretini yapın",
    icon: RiCurrencyLine,
  },
  {
    title: "Esnek Özelleştirme",
    description: "Tokenlarınızı kendi ihtiyaçlarınıza göre özelleştirin",
    icon: PiArrowElbowLeftBold,
  },
  {
    title: "Hızlı ve Verimli İşlemler",
    description:
      "Tokenizasyon işlemlerini hızlı ve verimli bir şekilde gerçekleştirin",
    icon: TiFlashOutline,
  },
  {
    title: "Bu Hizmetten Kimler Yararlanabilir?",
    description:
      "Aracı kurumlar, portföy yönetim şirketleri, bankalar, varlık yöneticileri, brokerlar, kripto varlık platformları ve diğer finansal ya da finans dışı kuruluşlar bu hizmetlerden yararlanabilir",
    icon: MdQuestionMark,
  },
];

const tokenizationUseCases = [
  {
    title: "Gayrimenkul ve Emtia",
    description:
      "Mülklerinizi ve altın, petrol gibi emtialarınızı dijital tokenlara dönüştürerek likidite sağlayın",
  },
  {
    title: "Sanat Eserleri ve Dijital Sanat",
    description:
      "Sanat eserlerinizi ve dijital içeriklerinizi (örneğin, NFT'ler) tokenize ederek daha geniş bir kitleye ulaştırın",
  },
  {
    title: "Hisse Senetleri ve Kripto Paralar",
    description:
      "Hisse senetlerinizi ve yeni oluşturulan kripto paralarınızı tokenlar ile daha hızlı ve güvenli bir şekilde ticaret yapın",
  },
  {
    title: "Oyun İçi Eşyalar ve Hizmetler",
    description:
      "Video oyunlarındaki eşyalar, karakterler ve diğer varlıklar ile uzmanlık hizmetleri, danışmanlık saatleri gibi hizmetlerinizi tokenize edin",
  },
  {
    title: "Fikri Mülkiyet ve Enerji Kredileri ",
    description:
      "Patentler, telif hakları ve enerji kredileri gibi varlıklarınızı tokenize ederek ticaretini ve yönetimini kolaylaştırın",
  },
  {
    title: "Lojistik, Sadakat Puanları ve Biletler",
    description:
      "Ürünlerin tedarik zinciri boyunca izlenebilirliği için lojistik verileri, sadakat programlarındaki puanlar ve etkinlik biletlerini tokenize edin",
  },
];

const qaData = [
  {
    question: "Tokenizasyon nedir?",
    answer:
      "Tokenizasyon, fiziksel veya dijital varlıkların dijital tokenlara dönüştürülmesi sürecidir",
  },
  {
    question: "Hangi varlık türleri tokenize edilebilir?",
    answer:
      "Devlet tahvilleri ve bonolar, Eurobond’lar, hisse senetleri, GYF ve GSYF, emtia ve gayrimenkul tokenize edilebilen varlıklar arasında yer alır",
  },
  {
    question: "Tokenizasyon işlemleri ne kadar güvenlidir?",
    answer:
      "Tokenizasyon işlemleri, en yüksek güvenlik standartları kullanılarak gerçekleştirilir. Blok zinciri teknolojisi kullanılarak işlemler şeffaf ve değiştirilemez hale getirilir. Blok zinciri, verilerin dağıtık bir defterde güvenli bir şekilde saklanmasını sağlar",
  },
  {
    question: "Tokenizasyonun avantajları nelerdir?",
    answer:
      "Tokenizasyon, likiditeyi artırır, daha küçük yatırımcılara erişim sağlar, işlem maliyetlerini düşürür ve varlıkların daha kolay alınıp satılmasını sağlar. Ayrıca, blok zinciri teknolojisi sayesinde şeffaflık ve izlenebilirlik sunar",
  },
  {
    question: "Bu Hizmetten Kimler Yararlanabilir?",
    answer:
      "Aracı kurumlar, portföy yönetim şirketleri, bankalar, varlık yöneticileri, brokerlar, kripto varlık platformları ve diğer finansal ya da finans dışı kuruluşlar bu hizmetlerden yararlanabilir",
  },
];

function Tokenization() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Varlıklarınızı güvenli ve hızlı bir şekilde dijital tokenlara dönüştürün."
        title2="Polyverse RWA’ları (Real World Assets-Gerçek Dünya Varlıkları) dijitalleştirme yolunda size öncülük eder"
        header1="Varlıklarınızı"
        header2="Dijital Tokenlara Dönüştürün"
        buttonText="Tokenizasyon"
        boxPadding="65px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <PvFeatureGrid items={features} />
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={tokenizationUseCases}
      />
      <QAAccordion faqData={qaData} />
      <ContactUs />
    </Box>
  );
}

export default Tokenization;
