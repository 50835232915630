import React from "react";
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Link,
  OrderedList,
} from "@chakra-ui/react";

const TermsOfService = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={32}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        Gizlilik ve Hizmet Düzeyi Anlaşması (SLA)
      </Heading>
      <Heading as="h2" mb={8} textAlign="center">
        Kullanım Koşulları
      </Heading>
      <Text mb={4} fontWeight="bold">
        Yürürlük Tarihi: 25 Ekim 2023
      </Text>

      <Text mt={4}>
        Hoş geldiniz! Bu Kullanım Koşulları ("Koşullar"), internet sitelerimize
        (
        <Link href="https://polyverse.life" isExternal color="blue.500">
          https://polyverse.life
        </Link>{" "}
        /{" "}
        <Link
          href="https://platform.polyverse.life/"
          isExternal
          color="blue.500"
        >
          https://platform.polyverse.life/
        </Link>{" "}
        /{" "}
        <Link href="https://web.norbi.app/" isExternal color="blue.500">
          https://web.norbi.app/
        </Link>
        ) ve mobil uygulamalarımıza (birlikte "Platform" olarak anılacaktır)
        erişiminizi ve bunların kullanımını, Polyverse ("Polyverse", "biz" veya
        "bizi") tarafından işletilen hizmetleri, ürünleri, etkinlikleri ve tüm
        ilgili bileşenleri, cüzdan ve web3-as-a-service özelliklerimiz dahil
        ancak bunlarla sınırlı olmamak üzere Dashboard, API, SDK, UI Bileşenleri
        ve Depolama hizmetlerini (birlikte "Polyverse Hizmetleri" veya
        "Hizmetler" olarak anılacaktır) kapsamaktadır.
      </Text>

      <Text mt={4}>
        Bu Hizmetler içinde, kodlama gerektirmeyen akıllı sözleşmeler
        aracılığıyla token oluşturma, uçtan uca NFT Kampanya Yönetimi, Polyverse
        Deneyim Alanı, Norbi, PolyMarket, PolyLand gibi bir dizi alt hizmet
        bulunmaktadır. Polyverse Hizmetleri, yeni işlevler, kütüphaneler,
        uygulamalar, merkeziyetsiz uygulamalar (dApps), web hizmetleri ve daha
        fazlasını kapsayacak şekilde gelişmeye devam ettikçe, bunların tümü
        'Hizmetler' terimi altında toplanmaktadır. Dolayısıyla, burada
        belirtilen sorumluluk reddi, Polyverse teknolojileri ile elde
        edilebilecek tüm olası eylemler ve sonuçlar için geçerlidir.
      </Text>

      <Text mt={4} fontWeight="bold">
        Bu Koşulları dikkatlice inceleyiniz, zira bu belge, Polyverse tarafından
        sağlanan Hizmetlerin kullanımına ilişkin yasal olarak bağlayıcı hüküm ve
        koşulları belirlemektedir.
      </Text>

      <Text mt={4}>
        Bu Koşulları kabul ederek, kendinizi ve temsil ettiğiniz herhangi bir
        kurumu bağlama hakkına ve yetkisine sahip olduğunuzu beyan ve taahhüt
        edersiniz. Ayrıca, bu Koşulları tamamen anladığınızı ve bunlara uymakla
        yükümlü olduğunuzu ve bu uyumu sağlamak sizin sorumluluğunuzda olduğunu
        kabul edersiniz.
      </Text>

      <Text mt={4}>
        Polyverse Hizmetlerine erişerek veya bunları kullanarak, kendi yargı
        bölgenizde yasal olarak reşit olduğunuzu ve Polyverse Hizmetlerine
        erişim ve kullanım için gerekli yasal yaşta olduğunuzu beyan edersiniz.
        Eğer yargı bölgenizde reşit değilseniz ancak en az on üç (13) yaşında
        iseniz, yasal vasinizin bu Koşulları gözden geçirmesi, size açıklaması
        ve sizin Polyverse Hizmetlerine erişiminiz ve kullanımınız için rızasını
        vermesi gerekmektedir.
      </Text>

      <Text mt={4}>
        Hizmetler, telif hakkı ile korunan eserlerdir. Polyverse ekosistemini
        oluşturan tüm unsurlar, akıllı sözleşme kodları, yazılım, kaynak
        kodları, şablonlar, metinler, resimler, videolar, ses dosyaları,
        grafikler, logolar, simgeler, veritabanları, raporlar, analizler ve
        Hizmetlerdeki diğer içerikler telif hakkı korumasına tabidir. Bu
        unsurların kullanımı veya çoğaltılması, bu Koşullarda belirtilenler
        dışında yasaktır.
      </Text>

      <Text mt={4}>
        Kişisel verilerinizi, Polyverse.life web sitesindeki "Gizlilik"
        bölümünde bulunan Gizlilik Politikamız kapsamında işlemekteyiz. Kişisel
        verilerinizi nasıl topladığımız, eriştiğimiz, kullandığımız,
        sakladığımız, aktardığımız ve koruduğumuz ve veri sahibi olarak
        haklarınız hakkında daha fazla bilgi için lütfen Gizlilik Politikamıza
        bakınız.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        1. Kişisel Kullanım Bireyleri ve Kişisel Kullanım İşletmelerine İlişkin
        Haklar ve Yükümlülükler
      </Heading>

      <Text mt={4}>
        1.1 Hizmetler, Hizmetlerden kendi kullanımları için ticari olarak
        yararlanmadan faydalanan bireyler ("Kişisel Kullanım Bireyleri") veya iç
        iş faaliyetleri sırasında Hizmetlerden faydalanan işletmeler ("Kişisel
        Kullanım İşletmeleri") tarafından kullanılabilecek özellikler
        içerebilir.
      </Text>

      <Text mt={4}>
        1.2 Cüzdan, mobil ve masaüstü platformlarında çalışan web tabanlı bir
        akıllı cüzdandır ve Kişisel Kullanım Bireylerine ve Kişisel Kullanım
        İşletmelerine, çeşitli Ethereum uyumlu blok zinciri ağlarında
        ("Desteklenen Blok Zinciri Ağları") doğrudan talimat yürütme yeteneği
        sağlar. Cüzdan ayrıca Kişisel Kullanım Bireylerine ve Kişisel Kullanım
        İşletmelerine Cüzdan ile uyumlu dijital varlıkların ("Blok Zinciri
        Varlıkları") bakiyelerini görüntüleme, Blok Zinciri Varlıkları içeren
        transferleri ve işlemleri başlatma ve potansiyel olarak diğer ürünlere
        ve oyunlara erişmek için kimlik doğrulama hesabı olarak kullanma
        yeteneği sağlar. Ek olarak, Cüzdan Kişisel Kullanım Bireylerine ve
        Kişisel Kullanım İşletmelerine merkeziyetsiz uygulamalara, merkeziyetsiz
        borsalar ("Dapps") dahil olmak üzere erişim sağlama olanağı verir ve bu
        uygulamalar aracılığıyla Blok Zinciri Varlıkları arasında takas (bu
        işlem "Takas" olarak anılacaktır) ve bir Desteklenen Blok Zinciri
        Ağından diğerine transfer (bu transfer "Köprü" olarak anılacaktır) gibi
        belirli işlemlere katılma imkanı tanır, herhangi bir sınırlama
        olmaksızın.
      </Text>

      <Text mt={4}>
        1.3 Polyverse, üçüncü taraf veri akışlarından elde edilen takas oranları
        ve dijital varlık fiyatlarından sorumlu değildir. Polyverse tüm
        sorumlulukları reddeder ve takas oranlarındaki veya dijital varlık fiyat
        tahminlerindeki tutarsızlıklardan doğan iddialardan veya kayıplardan
        Polyverse’i sorumlu tutmaz.
      </Text>

      <Text mt={4}>
        1.4 Polyverse, Cüzdan ile uyumlu Blok Zinciri Varlıkları ve Desteklenen
        Blok Zinciri Ağlarını kendi takdirine bağlı olarak belirler.
      </Text>

      <Text mt={4}>
        1.5 Bir Hesap oluşturmak ve bir Cüzdan oluşturmak için Kişisel Kullanım
        Bireyleri ve Kişisel Kullanım İşletmelerinin kimliklerini doğrulamak
        için gerekli bilgileri ve belgeleri (e-posta adresi dahil olmak üzere)
        bize sağlamaları gerekebilir. Kişisel Kullanım Bireyleri ve Kişisel
        Kullanım İşletmeleri, sağladıkları bilgilerin eksiksiz, doğru ve güncel
        olduğunu kabul ve taahhüt ederler. Kişisel Kullanım Bireylerinin ve
        Kişisel Kullanım İşletmelerinin kimliklerini doğrulayamazsak
        Cüzdanlarını oluşturma veya Hesaplarını oluşturma hakkını saklı tutarız.
      </Text>

      <Text mt={4}>
        1.6 Kişisel Kullanım Bireyi, kendi yargı bölgesinde reşit değilse, ancak
        en az on üç (13) yaşındaysa, ebeveyninin veya yasal vasisinin Cüzdan
        için kayıt işlemini gerekli bilgi ve belgeleri sağlayarak ve bu
        Koşulları kabul ederek onların adına yapması gerekmektedir. Kişisel
        Kullanım İşletmeleri için yetkili imza sahibinin kuruluş adına kayıt
        sürecini başlatması ve bu Koşulları kabul etmesi gerekmektedir.
      </Text>

      <Text mt={4}>
        1.7 Polyverse, herhangi bir zamanda ek bilgi ve belge talep edebilir ve
        talep edilen bilgi ve belgeleri sağlamadığınız takdirde Hesabınızı
        sonlandırma hakkını saklı tutar. Polyverse, yasal gerekliliklere uymak
        veya resmi talepler üzerine bilgilerinizi kamu otoriteleriyle
        paylaşabilir. (Kişisel verilerinizi nasıl işlediğimiz hakkında daha
        fazla bilgi edinmek için lütfen Gizlilik Politikamıza bakınız.)
      </Text>

      <Text mt={4}>
        1.8 Polyverse, Kişisel Kullanım İşletmelerinden Hizmetler karşılığında
        bir işlem ücreti talep edebilir. Polyverse, İşlem Ücretlerini kendi
        takdirine bağlı olarak belirleyecek ve platformları aracılığıyla
        duyuracaktır. Bu durumda, Kişisel Kullanım İşletmeleri bu işlem
        ücretinin transfer edilen tutardan düşüleceğini kabul ederler.
      </Text>

      <Text mt={4}>
        1.9 Cüzdan aracılığıyla gerçekleştirilen transferlerin geri
        alınamayacağını ve işlem ücretlerinin iade edilemeyeceğini kabul ve
        beyan edersiniz.
      </Text>

      <Text mt={4}>
        1.10 Polyverse, Cüzdan aracılığıyla gerçekleştirilen tüm transferlerin
        kayıtlarını tutabilir ve yasal gereklilikler veya talepler doğrultusunda
        yetkili kurum ve kuruluşlarla paylaşabilir.
      </Text>

      <Text mt={4}>
        1.11 Cüzdanınız, cihazınızda yerel olarak erişilebilen web tabanlı bir
        yazılımdır. Bu yazılımın bir bileşeni, Cüzdanınızı güvence altına almak
        için kullanılan "özel anahtar" sistemidir. Web3 kimlik doğrulama ve
        anahtar yönetimi için dünyanın en büyük kuruluşları tarafından
        kullanılan açık kaynak tor.us hizmetinden yararlanıyoruz. Ancak, hesap,
        parola ve özel anahtarınızın korunması ve gizliliğinden yalnızca sizin
        sorumlu olduğunuzu ve bunları unutmanız veya kaybetmeniz durumunda
        Polyverse’in herhangi bir kayıp, zarar veya iddiadan sorumlu olmadığını
        kabul ve beyan edersiniz.
      </Text>

      <Text mt={4}>
        1.12 Cüzdan, Blok Zinciri Varlıklarına erişiminizi ve transferinizi
        kolaylaştırır. Cüzdan aracılığıyla erişilebilen Blok Zinciri Varlıkları
        üzerindeki kontrol her zaman tamamen sizin kontrolünüz altında kalır.
        Polyverse, Cüzdandaki Blok Zinciri Varlıkları üzerinde herhangi bir
        yetki, kontrol, sahiplik veya karar verme gücüne sahip değildir. Bu Blok
        Zinciri Varlıklarına erişim ve bu varlıklarla işlem yapma yeteneğiniz,
        Cüzdan’dan bağımsız olarak çalışır ve Blok Zinciri Varlıklarınızı
        istediğiniz zaman başka bir cüzdana taşıma hakkınız vardır. Cüzdanınız
        aracılığıyla erişilebilen Blok Zinciri Varlıkları ile ilgili olarak
        Polyverse’in herhangi bir mali sorumluluğu veya bu varlıkları transfer
        etme yetkisi yoktur. Cüzdanınızdaki Blok Zinciri Varlıklarını transfer
        etme veya blok zincirinde kaydedilen diğer işlemleri başlatma yetkisi
        yalnızca size aittir. Dolayısıyla, Cüzdanı kullanırken Blok Zinciri
        Varlıklarınızın güvenliği, yönetimi ve ilgili risklerle ilgili olarak
        tüm sorumluluğun size ait olduğunu kabul, beyan ve taahhüt edersiniz ve
        Polyverse’in bu konuda hiçbir sorumluluğu bulunmamaktadır.
      </Text>

      <Text mt={4}>
        1.13 Polyverse, Cüzdan Hizmetlerinin sağlanmasını herhangi bir zamanda
        kendi takdirine bağlı olarak değiştirme veya sonlandırma hakkını saklı
        tutar. Polyverse, önceden bildirimde bulunmaksızın Cüzdan üzerinde
        değişiklik yapma hakkını açıkça saklı tutar.
      </Text>

      <Text mt={4}>
        1.14 Cüzdan, Blok Zinciri Varlıklarının satın alınması veya satılması
        işlemlerini etkinleştirebilir. Lütfen bu tür işlemlerin zaman zaman
        Polyverse tarafından belirlenebilecek işlem limitlerine tabi
        olabileceğini unutmayın. Herhangi bir işlem gerçekleştirmeden önce
        işlemle ilgili tüm bilgileri (fiyat oranlar, ücretler ve uyarılar dahil
        olmak üzere) dikkatlice incelemeniz gerekmektedir. İşlemin tüm yönlerini
        tam olarak anladığınızdan emin olmalısınız. Polyverse, Platform’daki
        Blok Zinciri Varlıklarının değerlemesi üzerinde hiçbir kontrol veya
        etkiye sahip değildir. Bu varlıkların değerleri, arz ve talep
        dinamiklerine göre belirlenir ve Polyverse, Blok Zinciri Varlıkları
        fiyat verilerinin doğruluğu, hatası veya eksikliği, bu tür verilerin
        iletimindeki hata veya gecikme ve bu verilerdeki kesinti ile ilgili
        hiçbir sorumluluk kabul etmez. Blok Zinciri Varlıklarının değerindeki
        değişikliklerden kaynaklanan kayıplardan veya zararlardan tamamen siz
        sorumlusunuz. Teknik ve diğer sınırlamalar nedeniyle, Platform’daki Blok
        Zinciri Varlıklarının görüntülenen fiyatları her zaman gerçek zamanlı
        piyasa değerlerini yansıtmayabilir.
      </Text>

      <Text mt={4}>
        1.15 Cüzdan Hizmetlerini kullanmanızla bağlantılı olarak aşağıdaki
        taahhütleri kabul, beyan ve taahhüt edersiniz:
      </Text>
      <UnorderedList mt={4} spacing={2}>
        <ListItem>
          a. Cüzdan Hizmetleri ile ilgili tüm fonlar veya varlıklar yasa dışı
          kaynaklardan gelmemeli veya yasa dışı amaçlar için kullanılmamalıdır.
          Para aklama veya terör örgütleriyle bağlantılı olduğundan şüphelenilen
          kişilerle veya yüksek riskli ülkelerle işlem yapmamalısınız.
        </ListItem>
        <ListItem>
          b. Polyverse tarafından sorulduğunda, bir işlemin amacı veya fonların
          kaynağı hakkında açıklama sağlamak dahil olmak üzere, Polyverse’in
          sorduğu soruları zamanında yanıtlamalısınız.
        </ListItem>
        <ListItem>
          c. Hizmetlere, Polyverse’e veya üçüncü taraflara zarar verebilecek
          faaliyet veya davranışlardan kaçınmalısınız. Bu, haklarını sınırlayan,
          mevcut zararı artıran, kârı azaltan veya Cüzdan Hizmetlerinin
          işleyişini bozan eylemleri içerir.
        </ListItem>
        <ListItem>
          d. Herhangi bir işlem gerçekleştirmeden önce sipariş yönü, cüzdan
          adresi, ağ seçimi, tutar, alıcının e-posta adresi, işlem ücretleri ve
          bildirimler dahil olmak üzere işlemin tüm unsurlarını dikkatlice
          okumalı ve emin olmalısınız. Polyverse, bu doğrulamaların yapılmaması
          nedeniyle oluşan hatalar, yanlışlıklar veya kayıplar için hiçbir
          sorumluluk kabul etmez. İşlem detaylarının doğruluğunu ve güvenliğini
          sağlamak sizin sorumluluğunuzdadır ve potansiyel finansal veya
          operasyonel sorunları en aza indirgemek için gerekli önlemleri
          almalısınız. Blok Zinciri Varlıkları ile ilgili işlemleri
          gerçekleştirebilecek bilgi ve deneyime sahip olduğunuzu kabul
          edersiniz.
        </ListItem>
      </UnorderedList>

      <Text mt={4}>
        1.16 Polyverse Hizmetlerini kullanmanızla bağlantılı olarak aşağıdaki
        beyanları yaparsınız:
      </Text>
      <UnorderedList mt={4} spacing={2}>
        <ListItem>
          a. Polyverse Hizmetlerini kendi yargı bölgenizde yasal olarak kullanma
          yetkisine sahip olduğunuzu, Blok Zinciri Varlıklarının sahipliğini ve
          Polyverse Hizmetleri ile olan diğer etkileşimlerinizi içerir şekilde
          beyan ve taahhüt edersiniz. Kendi yargı bölgenizin yasalarına ve
          düzenlemelerine uygunluğu sağlamakla tamamen sorumlu olduğunuzu ve
          Polyverse’in bu yasalarla uyumunuzdan veya uyumsuzluğunuzdan sorumlu
          tutulamayacağını kabul edersiniz.
        </ListItem>
        <ListItem>
          b. Adınızın veya listenizin şu listelerden herhangi birinde
          bulunmadığını beyan ve taahhüt edersiniz: (i) Ceza Kanunu (Kanada)
          Madde 83.05 kapsamında oluşturulan herhangi bir liste, Birleşmiş
          Milletler Yasası (Kanada) uyarınca çıkarılan herhangi bir Yönetmelik,
          Yolsuz Yabancı Yetkililerin Malvarlığını Dondurma Yasası (Kanada),
          Yolsuz Yabancı Yetkililer için Adalet Yasası (Kanada), Özel Ekonomik
          Önlemler Yasası (Kanada) veya Türkiye’de terörizmin finansmanı ve
          yaptırımlı kişiler veya kuruluşlarla finansal etkileşimler konusunda
          benzer herhangi bir yasa veya yönetmelik; (ii) ABD Hazine Bakanlığı
          Yabancı Varlıkları Kontrol Ofisi tarafından tutulan özel olarak
          belirlenmiş vatandaşlar ve/veya engellenmiş kişiler listesi; (iii) ABD
          Ticaret Bakanlığı Sanayi ve Güvenlik Bürosu tarafından yönetilen
          reddedilen kişiler veya endişe duyulan kuruluşlar listesi; veya (iv)
          ABD’nin herhangi bir ajansı, bakanlığı veya departmanı tarafından
          yayımlanan benzer bir liste.
        </ListItem>
        <ListItem>
          c. Türkiye, Kanada veya ABD tarafından yasal sınırlamalar konmuş
          herhangi bir ülkede ikamet etmediğinizi veya bulunmadığınızı beyan ve
          taahhüt edersiniz.
        </ListItem>
        <ListItem>
          d. Cüzdanınıza erişimi satın almayacağınızı, satmayacağınızı,
          kiralamayacağınızı veya devretmeyeceğinizi, yanlış veya yanıltıcı
          kimlik veya bilgi ile Cüzdan oluşturmayacağınızı veya üçüncü bir taraf
          adına işlem yapmayacağınızı taahhüt edersiniz. Bize sağladığınız tüm
          bilgilerin doğru, güncel ve eksiksiz olduğunu ve gerektiğinde bu
          bilgileri güncelleyeceğinizi kabul edersiniz. Aksi takdirde
          Polyverse’in erişiminizi veya Hesabınızı sonlandırabileceğini kabul
          edersiniz. Hesabınızın yetkisiz kişiler tarafından kullanıldığını veya
          tehlikeye girdiğini düşünüyorsanız derhal Polyverse’e bildirmelisiniz.
        </ListItem>
        <ListItem>
          e. Cüzdanın normal işleyişini bozmayacağınızı, müdahale etmeyeceğinizi
          veya başka bir kullanıcının Cüzdan deneyimini olumsuz etkileyebilecek
          herhangi bir şekilde hareket etmeyeceğinizi beyan edersiniz. Bu,
          bilinen hataları istismar etmek veya Cüzdanın tasarımını kasıtlı
          olarak kötüye kullanmak veya çiğnemek anlamına gelir.
        </ListItem>
      </UnorderedList>

      <Text mt={4}>
        1.17 Cüzdan kullanımına ilişkin aşağıda belirtilen riskleri kabul etmeyi
        kabul edersiniz:
      </Text>
      <UnorderedList mt={4} spacing={2}>
        <ListItem>
          a. Cüzdan, Polyverse’in sorumluluğu olmayan belirli güvenlik
          açıklarına karşı hassas olabilir. Polyverse, Cüzdanın güvenliğini
          artırmak için çeşitli önlemler almış olmasına rağmen, teknoloji hala
          erken aşamalarında olduğundan, kodun tamamen hatasız veya eksiksiz
          olduğunu garanti etmek mümkün değildir. Cüzdan kullanımından
          doğabilecek tüm riskleri, potansiyel mali kayıplar dahil olmak üzere,
          kabul etmeyi kabul edersiniz.
        </ListItem>
        <ListItem>
          b. Giriş Bilgilerinizi ele geçiren ve cihazınıza erişen herhangi bir
          kişi, sizin izniniz olmasa da, Cüzdanınıza erişebilir ve Cüzdanınız
          aracılığıyla erişilebilen Blok Zinciri Varlıklarını transfer edebilir.
        </ListItem>
        <ListItem>
          c. Blok Zinciri Varlıklarının değeri değer verildiğinde
          dalgalanabilir. Polyverse, bir Cüzdan aracılığıyla erişilebilen
          herhangi bir Blok Zinciri Varlığının fiyatı veya değeri konusunda
          herhangi bir garanti veya beyan vermez. Blok Zinciri Varlıklarınızın
          veya daha sonra edinebileceğiniz bu tür varlıkların piyasa oynaklığı,
          önemli fiyat kayması ve ilgili maliyetler nedeniyle tamamen veya
          kısmen değer kaybedebileceğini kabul eder ve riski üstlenirsiniz. Bu
          madde, Cüzdanı kullanarak üçüncü taraf Dapps ile etkileşim kurarak
          edindiğiniz Blok Zinciri Varlıkları için de geçerlidir.
        </ListItem>
        <ListItem>
          d. Blok Zinciri Varlıklarının kullanımı, özel anahtarların kaybolma
          riski, özel anahtara sahip üçüncü şahıslar tarafından yetkisiz erişim
          ve hırsızlık, ikincil piyasalarda piyasa değeri dalgalanmaları, ağ
          tıkanıklığı nedeniyle ilgili blok zincirinde kesintiler, varlıkların
          hard fork veya diğer bozulmalar nedeniyle çalışmaması, belirli bir
          Blok Zinciri Varlığı veya Blok Zinciri Varlıklarını içeren işlemlerle
          ilgili akıllı sözleşme kodundaki hatalar veya güvenlik açıkları gibi
          riskler taşır. Ethereum platformu da dahil olmak üzere blok
          zincirlerinde yapılan işlemler büyük ölçüde geri alınamaz
          niteliktedir. Bir Blok Zinciri Varlığını bir blok zinciri adresinden
          diğerine transfer etmek için gerekli özel anahtar(lar) ile doğrulanmış
          bir talimatın yürütülmesi durumunda söz konusu işlem geri alınamaz
          hale gelir.
        </ListItem>
        <ListItem>
          e. Blok Zinciri Varlıkları ile ilgili işlemlerle ilgili vergi
          sonuçlarını ve vergi raporlama yükümlülüklerini belirlemekten yalnızca
          siz sorumlusunuz ve ilgili vergileri ödemek zorundasınız. Polyverse,
          Cüzdanlar aracılığıyla Blok Zinciri Varlıkları ile ilgili işlemlerin
          vergi sonuçlarını belirlemek, bu tür işlemleri belgelemek veya ilgili
          vergileri ödemekle ilgili hiçbir sorumluluk kabul etmez.
        </ListItem>
        <ListItem>
          f. Temel blok zinciri ağına yapılan değişiklikler veya mevcut blok
          zincirini bir (veya daha fazla) ek blok zincirine bölünmesine yol açan
          herhangi bir ağ bozulması durumunda, Cüzdan bu fork sonucunda ortaya
          çıkan yeni Blok Zinciri Varlıkları ile ilgili faaliyetleri
          desteklemeyebilir. Ayrıca böyle bir fork olayı sırasında ağ içindeki
          işlemler kesintiye uğrayabilir.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="lg" mt={8}>
        2. B2B Müşterilerine İlişkin Haklar ve Yükümlülükler
      </Heading>

      <Text mt={4}>
        2.1 Hizmetler, Platform’u kullanarak kendi kullanıcılarına
        platform-as-a-service olarak hizmet sunan B2B Müşterileri ("B2B
        Müşterileri") tarafından kullanılabilecek özellikler içerebilir. Bu
        Bölüm 2 özel olarak B2B Müşterileri için geçerlidir. Kişisel Kullanım
        Bireyi veya Kişisel Kullanım İşletmesi iseniz, bu Koşulların 1. Bölümü
        sizin için geçerli olacaktır. Hizmetleri hem kendiniz hem de
        kullanıcılarınıza hizmet sunmak için kullanıyorsanız, ilgili hizmetler
        için her iki Bölüme de tabi olacaksınız.
      </Text>

      <Text mt={4}>
        2.2 B2B Müşterileri, Hizmetlere erişmek için bir hesap oluşturmalıdır.
        B2B Müşterilerinin kullanabileceği Hizmetler arasında ancak bunlarla
        sınırlı olmamak üzere şunlar bulunmaktadır:
      </Text>

      <UnorderedList mt={4} spacing={2}>
        <ListItem>
          a. Dashboard: Dashboard, Web3 geliştirmeyi basitleştirmek için bir
          araçtır. B2B Müşterilerine merkeziyetsiz projeler oluşturma ve
          yönetme, akıllı sözleşmeler oluşturma ve dağıtma, NFT oluşturma ve
          dağıtma, yerleşik ve özel sözleşmelere erişme ve blok zinciri verileri
          hakkında ayrıntılı bilgiler edinme imkanı sunar.
        </ListItem>
        <ListItem>
          b. SDK (Yazılım Geliştirme Kiti): SDK, B2B Müşterilerinin
          merkeziyetsiz uygulamalar (dApps) oluşturmasını, dağıtmasını ve
          yönetmesini sağlar. Gelişmiş güvenlik protokolleri, popüler
          çerçevelerle sorunsuz entegrasyon ve proje gereksinimlerini
          karşılayacak ölçeklenebilirlik sunar.
        </ListItem>
        <ListItem>
          c. API (Uygulama Programlama Arayüzü): API, B2B Müşterilerinin
          uygulamalarını Polyverse Hizmetlerinin merkeziyetsiz yetenekleri ile
          entegre etmeleri için tasarlanmıştır. Zincir bağımsız etkileşimler,
          güvenlik özellikleri, kapsamlı uç noktalar ve gerçek zamanlı
          güncellemeler sunar.
        </ListItem>
        <ListItem>
          d. Depolama: Depolama, B2B Müşterileri için merkeziyetsiz bir depolama
          çözümüdür. Merkeziyetsiz yedeklilik, uçtan uca şifreleme, optimize
          edilmiş veri erişimi ve çeşitli veri türlerini depolama esnekliği
          sunar.
        </ListItem>
        <ListItem>
          e. UI Bileşenleri: UI Bileşenleri, web3 uygulamaları için kullanıcı
          arayüzü öğeleridir. Duyarlı tasarım, web3 uyumlu öğeler,
          özelleştirilebilir temalar ve etkileşimlilik özelliklerine sahiptir.
        </ListItem>
        <ListItem>
          f. Üretken AI SDK: Platform, AI hizmetlerinin entegrasyonu ve
          kullanımı için bir Yazılım Geliştirme Kiti (SDK) sunar. Bu SDK, B2B
          Müşterilerinin projelerine ve uygulamalarına üretken AI yeteneklerini
          sorunsuz bir şekilde dahil etmelerini sağlar. Bu AI hizmetleri,
          Platform’daki üçüncü taraf sağlayıcılar tarafından sunulmakta olup,
          B2B Müşterileri kullanım sonuçları ve ilgili sorumluluklar dahil olmak
          üzere bu hizmetlerin kullanımından tamamen sorumludur. Polyverse,
          üçüncü taraf AI platformlarını uygulamalarınıza sorunsuz bir şekilde
          entegre etme olanağı sağlayarak AI hizmetlerinin kullanımını
          kolaylaştırır. AI hizmetleri ile ilgili olarak kullanıcı verilerinin
          işlenmesi, hizmet kullanılabilirliği, hatalar, veri ihlalleri, içerik,
          yasal uygunluk ve üçüncü taraf AI sağlayıcılarının uyumluluğu
          konusunda Polyverse herhangi bir sorumluluk kabul etmez. Polyverse,
          üçüncü taraflarca oluşturulan AI modelleri veya algoritmalarının
          sahipliğini iddia etmez ve AI hizmetleri için destek veya bakım
          sağlamaz. Üretken AI SDK hizmetlerinin sonlandırılması veya askıya
          alınması konusundaki kararlar tamamen Polyverse’in takdirindedir.
        </ListItem>
      </UnorderedList>

      <Text mt={4}>
        2.3 Polyverse, B2B Müşterileri tarafından denetlenen ve kontrol edilen
        çalışanlarına Hizmetlere erişim izni verebilir. B2B Müşterileri,
        Hizmetlere herhangi bir şekilde erişmesi gereken üçüncü taraf hizmet
        sağlayıcılar gibi çalışan olmayan kişilerin yetkilendirilmesi için
        Polyverse’ten izin talep etmelidir.
      </Text>

      <Text mt={4}>
        2.4 B2B Müşterileri, Hizmetleri kendi işlerini, projelerini ve
        müşterilerini yönetmek için kullanabilirler. Ancak, B2B Müşterileri,
        Hizmetleri veya Hizmetlerin bir kısmını herhangi bir üçüncü tarafa
        ticari olarak sunamazlar veya erişim sağlayamazlar.
      </Text>

      <Text mt={4}>
        2.5 B2B Müşterileri, Hizmetlere erişmek için yeterli bant genişliği ve
        kalitede bir internet bağlantısı ve desteklenen bir web tarayıcısı
        edinmek ve bunları sürdürmekle sorumludur. B2B Müşterileri, Polyverse’in
        Hizmetlerin sorunsuz sağlanması için zaman zaman ek makul gereksinimler
        tanımlayabileceğini anlar ve kabul ederler.
      </Text>

      <Text mt={4}>
        2.6 B2B Müşterileri, Hizmetlerde güncellemeler veya özelleştirmeler
        talep ederlerse Polyverse talebi değerlendirecek ve devam edip
        etmeyeceğine kendi takdirine bağlı olarak karar verecektir. B2B
        Müşterileri, Hizmetlerin geliştirilmiş işlevselliğinden yararlanmak ve
        uyumluluğu sürdürmek için sistemlerinde belirli yükseltmeleri yapmaları
        gerekebileceğini kabul ederler.
      </Text>

      <Text mt={4}>
        2.7 B2B Müşterileri, Polyverse’in yalnızca teknolojiyi sağladığını kabul
        ederler. B2B Müşterileri, Hizmetleri kullanarak oluşturdukları projeler,
        tokenlar, NFT'ler ve akıllı sözleşmelerden tamamen kendileri sorumludur.
        Polyverse, B2B Müşterilerinin Hizmetleri kullanarak gerçekleştirdiği
        faaliyetler, token oluşturma, blok zinciri projeleri, dApps,
        tokenomikler, oyunlar, ödüller, NFT'ler, NFT kampanyaları, DeFi
        işlemleri, dijital varlık gönderme ve alma işlemleri, alım satımlar,
        ödünç verme veya borç alma faaliyetleri, piyangolar, oylama süreçleri,
        tüm 'hizmet olarak' özellikler, pazar yerleri, dağıtım hizmetleri vb.
        dahil ancak bunlarla sınırlı olmamak üzere faaliyetlerden kaynaklanan
        kâr, zarar veya iddialar için herhangi bir sorumluluk kabul etmez.
        Polyverse, Hizmetleri kapsamında kullanılan akıllı sözleşmeler, akıllı
        sözleşme şablonları veya sunucu hizmetlerinden kaynaklanan tüm yasal ve
        ticari sorunlar ve riskler tamamen B2B Müşterilerinin sorumluluğundadır.
      </Text>

      <Text mt={4}>
        2.8 B2B Müşterilerine sunulan Hizmetler, Blok Zinciri Varlıklarının
        değiştirilmesi ve yönetilmesi hizmetlerini içermez.
      </Text>

      <Text mt={4}>
        2.9 B2B Müşterileri, Polyverse Hizmetlerini kullanarak
        gerçekleştirdikleri faaliyetler ve Polyverse Hizmetlerini kullanarak
        oluşturdukları tokenlar ve uygulamalarla ilgili olarak federal, eyalet
        ve yerel yasalar, kurallar ve düzenlemeler, Anti-Money Laundering (AML)
        ve Know Your Customer (KYC) yükümlülükleri ve fatura ödeme, finansal
        işlemler ve vergi raporlama ile ilgili düzenlemelere tabi olabileceğini
        kabul ederler. Polyverse Hizmetlerini kullanırken ve bu hizmetler
        aracılığıyla oluşturdukları tokenlar ve uygulamaları kullanırken tüm
        geçerli yasalara, kurallara ve düzenlemelere uyma sorumluluğu tamamen
        B2B Müşterilerine aittir. Polyverse, B2B Müşterilerinin Hizmetleri
        kullanımıyla ilgili herhangi bir sorumluluk kabul etmez.
      </Text>

      <Text mt={4}>
        2.10 B2B Müşterileri, Hizmetleri kullanarak herhangi bir yargı
        bölgesinde lisans veya izin gerektiren herhangi bir faaliyeti izinsiz
        olarak gerçekleştirmemeyi ve bu faaliyetleri yürütmek için gerekli
        lisans veya izne sahip olmayı kabul ederler.
      </Text>

      <Text mt={4}>
        2.11 Polyverse, Hizmetlerden herhangi bir içeriği kendi takdirine bağlı
        olarak silebilir. B2B Müşterileri, Hizmetlere yükledikleri içeriğin
        kopyalarını saklamalıdırlar. Ancak Polyverse, B2B Müşterileri tarafından
        oluşturulan yasa dışı içeriği proaktif olarak izlemek veya kaldırmakla
        yükümlü değildir. B2B Müşterileri kendi içeriklerinden tamamen
        sorumludur.
      </Text>

      <Text mt={4}>
        2.12 B2B Müşterileri, kullanıcılarının Polyverse’e işlem ücretlerini
        ödemesinden sorumlu olabilirler. Polyverse, B2B Müşterilerine bu
        ücretleri kullanıcıları adına karşılama seçeneği sunabilir.
        Kullanıcıların herhangi bir nedenle Polyverse’e işlem ücretlerini
        ödeyememesi durumunda, B2B Müşterileri bu ücretleri ödeme sorumluluğunu
        üstlenebilirler.
      </Text>

      <Text mt={4}>
        2.13 B2B Müşterileri, tüm geçerli veri koruma düzenlemelerine uymayı ve
        kendi amaçları ve araçları için işledikleri kullanıcı kişisel verileri
        konusunda tek veri denetleyicisi olduklarını kabul ederler.
      </Text>

      <Text mt={4}>
        2.14 B2B Müşterileri, Polyverse’in B2B Müşterilerinin ve
        kullanıcılarının anonimleştirilmiş ve toplu verilerini istatistik ve
        analiz amaçları için kullanabileceğini kabul ederler.
      </Text>

      <Text mt={4}>
        2.15 B2B Müşterileri, kullanıcılarının Platform’daki faaliyetlerinden
        sorumludur. B2B Müşterileri, kullanıcıları ile yaptıkları sözleşmelere
        Polyverse Hizmetleri için en yüksek düzeyde koruma sağlayacak hükümleri
        eklemeyi taahhüt ederler. B2B Müşterileri, kullanıcıları nedeniyle
        Polyverse’in maruz kaldığı zarar veya kayıplar için Polyverse’i tazmin
        etmeyi kabul ederler.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        3. Fikri Mülkiyet
      </Heading>

      <Text mt={4}>
        3.1 Tarafınızca sağlanan herhangi bir içerik hariç, Hizmetler ve
        Platform üzerindeki tüm fikri mülkiyet haklarının, telif hakları,
        patentler, ticari markalar, ticari sırlar, akıllı sözleşme kodları,
        yazılım kaynak kodları, şablonlar, metinler, resimler, videolar, ses
        dosyaları, grafikler, logolar, simgeler, veritabanları, raporlar,
        analizler ve diğer içerik dahil olmak üzere Polyverse’e ait olduğunu
        kabul edersiniz. Bu Koşullar veya Hizmetlere ve Platforma erişiminiz, bu
        fikri mülkiyet haklarına ilişkin herhangi bir hak, mülkiyet veya çıkarı
        size veya üçüncü bir tarafa devretmez, bu Koşullarda açıkça belirtilen
        sınırlı erişim hakları hariç olmak üzere. Polyverse, bu Koşullarda
        açıkça verilmemiş tüm hakları saklı tutar. Bu Koşullar kapsamında zımni
        lisanslar verilmez.
      </Text>

      <Text mt={4}>
        3.2 Herhangi bir metin, resim, video, animasyon, telif hakkına tabi eser
        veya diğer içerik ve materyalleri yüklemek, kullanmak veya göndermekle
        ilgili olarak bu içeriklerin Polyverse Hizmetleri ile bağlantılı olarak
        kullanımının üçüncü tarafların fikri mülkiyet hakları, gizlilik hakları,
        tanıtım hakları veya diğer haklarının ihlaline, kötüye kullanılmasına
        veya başka bir şekilde ihlal edilmesine neden olmayacağını beyan ve
        taahhüt edersiniz. Sözleşme Pazarında paylaşılan veya satılan bir akıllı
        sözleşmenin fikri mülkiyet haklarının ihlal edildiği tespit edilirse
        veya üçüncü tarafların fikri mülkiyet iddialarına tabi olursa,
        oluşabilecek zararlardan tamamen sorumlu olmayı kabul edersiniz.
        Polyverse’e bu tür fikri mülkiyet anlaşmazlıklarının çözümünde tam
        destek sağlamalısınız. Bu destek, anlaşmazlıkları ele almak ve çözmek
        için gerekli bilgi, belge veya diğer yardımların sağlanmasını
        içerecektir.
      </Text>

      <Text mt={4}>
        3.3 Polyverse, bu Koşullara uygun olarak Hizmetlere ve Platforma erişim
        ve kullanım için size sınırlı, münhasır olmayan, geri alınabilir ve
        devredilemez bir hak verir. Kişisel Kullanım Bireyleri ve Kişisel
        Kullanım İşletmeleri, Cüzdanı yalnızca kişisel ve ticari olmayan
        kullanımları için lisanslamışlardır. B2B Müşterileri, bu Koşullara uygun
        olarak projelerini oluşturmak, tasarlamak veya geliştirmek için akıllı
        sözleşme şablonları, kodları, yazılım geliştirme kitleri ve uygulama
        programlama arayüzlerini kullanmak için lisanslanmıştır.
      </Text>

      <Text mt={4}>
        3.4 Aşağıdaki kısıtlamalara uymayı kabul eder ve taahhüt edersiniz:
      </Text>
      <UnorderedList mt={4} spacing={2}>
        <ListItem>
          a. Polyverse tarafından yazılı olarak açıkça izin verilmedikçe,
          Hizmetleri veya Platformu (kısmen veya tamamen) veya Hizmetler ve
          Platformda görüntülenen herhangi bir içeriği lisanslamayacak,
          satmayacak, kiralamayacak, devretmeyecek, dağıtmayacak, kamuya açık
          olarak göstermeyecek, türev eserler oluşturmayacak, ticari olarak
          kullanmayacak veya başka bir şekilde değerlendirmeyecek veya istismar
          etmeyeceksiniz.
        </ListItem>
        <ListItem>
          b. Hizmetlerin herhangi bir bölümünü değiştirmeyecek, çözmeyecek,
          tersine mühendislik yapmayacak, kaynak kodu veya temel fikirleri veya
          algoritmaları başka bir şekilde elde etmeye çalışmayacaksınız.
        </ListItem>
        <ListItem>
          c. Hizmetlere erişim sağlamak için benzer veya rekabetçi bir web
          sitesi, ürün veya hizmet oluşturmayacaksınız.
        </ListItem>
        <ListItem>
          d. Hizmetlerin herhangi bir bölümünü tercüme etmeyecek veya başka
          şekilde türev eserler, adaptasyonlar, çeviriler veya derlemeler
          oluşturmayacaksınız.
        </ListItem>
        <ListItem>
          e. Burada açıkça belirtilen haklar dışında, kiralamayacak,
          devretmeyecek veya başka bir şekilde hakları transfer etmeyeceksiniz.
        </ListItem>
        <ListItem>
          f. Polyverse’in açık yazılı izni olmadan Hizmetlerin herhangi bir
          bölümünü çerçevelemeyecek veya aynalayamayacaksınız.
        </ListItem>
        <ListItem>
          g. Hizmetlerle ilgili içeriği sistematik olarak indirmeyecek ve bir
          veritabanı oluşturmak için saklamayacaksınız.
        </ListItem>
        <ListItem>
          h. Polyverse’in açık yazılı izni olmadan, Hizmetlerle ilgili içeriği
          almak, indekslemek, kazımak, veri madenciliği yapmak veya başka bir
          şekilde toplamak için robotlar, örümcekler, site arama/getirme
          uygulamaları veya diğer manuel veya otomatik cihazları
          kullanmayacaksınız veya Hizmetlerin navigasyon yapısını veya sunumunu
          yeniden oluşturmayacak veya aşmayacaksınız.
        </ListItem>
        <ListItem>
          i. Burada açıkça belirtilmedikçe, Hizmetlerin veya Platformun herhangi
          bir bölümü herhangi bir şekilde kopyalanamaz, çoğaltılamaz,
          dağıtılamaz, yeniden yayınlanamaz, indirilemez, gösterilemez,
          gönderilemez veya iletilemez. Hizmetler veya Platform üzerindeki tüm
          telif hakları ve diğer mülkiyet bildirimleri (veya üzerlerinde
          görüntülenen içerik) tüm kopyalarda korunmalıdır.
        </ListItem>
      </UnorderedList>

      <Heading as="h2" size="lg" mt={8}>
        4. Ücretler
      </Heading>

      <Text mt={4}>
        4.1 Kullandığınız belirli Polyverse Hizmetlerine bağlı olarak ücretlere
        tabi olabilirsiniz. Önceden bildirimde bulunmaksızın bu ücretleri
        değiştirme hakkını açıkça saklı tutarız.
      </Text>

      <Text mt={4}>
        4.2 Ücret bilgilerini doğru sağlamaya çalışırken, bu ayrıntılar sadece
        ücret tahminlerimizi yansıtır ve Polyverse Hizmetlerini kullanma ve
        ilgili blok zincirle etkileşimde bulunma için gerçekleşen gerçek
        ücretlerden sapabilir. Dijital varlık fiyatlandırma bilgileri, benzer
        hizmet platformlarının sunduğu oranlardan daha yüksek veya daha düşük
        olabilir.
      </Text>

      <Text mt={4}>
        4.3 Her Takas ile ilgili olarak, takas oranlarının ve dijital varlık
        fiyatlarının üçüncü taraf veri akışlarından elde edildiğini ve
        bildirimde bulunmaksızın dalgalanabileceğini kabul ve beyan edersiniz.
        Dolayısıyla, Polyverse Hizmetleri aracılığıyla Takas arayüzü dahil
        görüntülenen oranlar veya fiyatlar yalnızca gösterge niteliğindedir ve
        hatalı olabilir. Polyverse, Takas oranlarındaki veya dijital varlık
        fiyat tahminlerindeki tutarsızlıklardan doğan iddialardan veya
        kayıplardan hiçbir sorumluluk kabul etmez ve Polyverse’i sorumlu
        tutmazsınız.
      </Text>

      <Text mt={4}>
        4.4 Her Desteklenen Blok Zinciri Ağı, altyapısında işlem yapmak için bir
        ağ işlem ücreti alır. Bu ağ işlem ücretleri çeşitli faktörlere bağlı
        olarak zaman içinde değişebilir. Polyverse Hizmetlerini kullanarak her
        blok zinciri ağında gerçekleştirdiğiniz faaliyetlerle ilişkili ağ işlem
        ücretlerini ödemekle yalnızca siz sorumlusunuz. Ayrıca, Cüzdan
        kullanımınızla ilgili olarak ortaya çıkabilecek herhangi bir üçüncü
        taraf ücretinden de yalnızca siz sorumlusunuz.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        5. Üçüncü Taraf Hizmetleri
      </Heading>

      <Text mt={4}>
        5.1 Polyverse Hizmetleri, bağlantılar veya Üçüncü Taraf Hizmetlerine
        erişim sağlama olanağı içerebilir, Dapps dahil ancak bunlarla sınırlı
        olmamak üzere. Bu Üçüncü Taraf Hizmetleri, Takaslar ve Köprüler gibi
        özellikleri etkinleştirebilir. Bir Dapp veya başka bir Üçüncü Taraf
        Hizmetini kullanırken, varlıklarınızın herhangi birini bize
        devretmediğinizi kabul ve anlıyorsunuz. Polyverse bu Üçüncü Taraf
        Hizmetlerine erişim sağlama olanağını yalnızca kullanıcı kolaylığı için
        sağlar. Polyverse, Üçüncü Taraf Hizmetleri tarafından yayımlanan içerik
        üzerinde kontrol sahibi değildir ve bu tür Üçüncü Taraf Hizmetleri için
        hiçbir garanti veya onay vermez. Ayrıca, Polyverse, bu Üçüncü Taraf
        Hizmetleri aracılığıyla sunulan veya erişilebilen içerik, ürünler veya
        hizmetlerin mevcut olup olmamasından veya özgünlüğünden hiçbir
        sorumluluk kabul etmez. Bu Üçüncü Taraf Hizmetlerine yönelik
        bağlantılar, sahipleri, içerikleri veya tekliflerinin uygunluğu ile
        ilgili olarak açık veya zımni hiçbir garanti veya beyan vermez. Üçüncü
        Taraf Hizmetlerle olan etkileşimlerinizle ilgili tüm riskleri tek
        başınıza kabul eder ve üstlenirsiniz.
      </Text>

      <Text mt={4}>
        5.2 Üçüncü şahıslar, Üçüncü Taraf Hizmetlerini kullanmanız için sizden
        ücret talep edebilir. Örneğin, Cüzdan aracılığıyla eriştiğiniz
        Dapps’lardan ücret alabilirsiniz. Bu üçüncü taraf ücretleri Polyverse
        tarafından tahsil edilmez veya ödenmez. Polyverse, Üçüncü Taraf
        Hizmetleri aracılığıyla veya bunlarla bağlantılı olarak erişilen
        hizmetlerle ilgili olarak sizden alınan ücretlerle ilgili herhangi bir
        sorumluluk kabul etmez.
      </Text>

      <Text mt={4}>
        5.3 Bu Üçüncü Taraf Hizmetlerinin kullanımınız, ilgili üçüncü taraf
        hizmetleri tarafından belirlenen geçerli gizlilik politikalarına,
        kullanım koşullarına, benzer politikalara ve ücretlere tabi olacaktır.
      </Text>

      <Text mt={4}>
        5.4 POLYVERSE HERHANGİ BİR ÜÇÜNCÜ TARAFI DESTEKLEMEZ VE BAŞKA
        KULLANICILARLA YAPTIĞINIZ HERHANGİ BİR İŞLEM İÇİN HERHANGİ BİR
        SORUMLULUK KABUL ETMEZ. POLYVERSE’İN HERHANGİ BİR KAYIP VEYA ZARAR İÇİN
        SORUMLU OLMADIĞINI KABUL VE BEYAN EDERSİNİZ.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        6. Feragatnameler
      </Heading>

      <Text mt={4}>
        6.1 Polyverse, bir Blok Zinciri Varlığı değişim platformu işletmez,
        ticaret yürütme veya takas hizmetleri sunmaz. Dolayısıyla, Polyverse
        yasal gereklilikler dışında, Polyverse Hizmetleri aracılığıyla
        gerçekleştirilen işlemler üzerinde herhangi bir denetim, katılım veya
        kontrol yetkisine sahip değildir. Polyverse Hizmetleri kullanıcıları
        arasındaki tüm işlemler, üçüncü taraf akıllı sözleşmeler aracılığıyla
        doğrudan kullanıcıların Ethereum (veya başka bir ağ) adresleri arasında
        eşler arası olarak gerçekleştirilir. Polyverse Hizmetlerini
        kullanımlarınızla ilgili tüm geçerli yasalara uyma sorumluluğu yalnızca
        size aittir.
      </Text>

      <Text mt={4}>
        6.2 Bu Koşulların tarihi itibariyle Polyverse’in Ontario Menkul
        Kıymetler Komisyonu, Emtia Vadeli İşlemler Ticaret Komisyonu ("CFTC"),
        Menkul Kıymetler ve Borsa Komisyonu ("SEC") veya Kanada, Amerika
        Birleşik Devletleri, Türkiye veya herhangi bir başka yargı bölgesindeki
        herhangi bir mali düzenleyici otorite tarafından kayıtlı veya lisanslı
        olmadığını kabul edersiniz. Bugüne kadar herhangi bir mali düzenleyici
        otorite, Polyverse Hizmetlerinin kullanımını gözden geçirmemiş veya
        onaylamamıştır. Polyverse Hizmetleri tarafından sağlanan bilgi ve
        materyaller yalnızca bilgilendirme amaçlıdır. Polyverse Hizmetleri,
        herhangi bir emtia, menkul kıymet veya başka bir Blok Zinciri Varlığı
        veya enstrüman ile ilgili tavsiye, öneri, teklif veya davet sunmaz veya
        böyle bir iddiada bulunmaz. Polyverse, herhangi bir kişi veya kuruluşa
        yatırım danışmanı veya emtia ticaret danışmanı olarak hareket etmez.
        Hizmetlerde yer alan hiçbir şey, herhangi bir yatırım enstrümanını satın
        alma veya satma önerisi veya getiri vaadi olarak yorumlanamaz.
        Polyverse’in öneriler, tavsiyeler, vaatler veya danışmanlıklar temelinde
        Polyverse Hizmetleri üzerindeki işlemlerinizi gerçekleştirmediğinizi ve
        gerçekleştirmeyeceğinizi açıkça kabul edersiniz.
      </Text>

      <Text mt={4}>
        6.3 Polyverse, herhangi bir Desteklenen Blok Zinciri Ağının işlevselliği
        konusunda herhangi bir beyan veya garanti vermez ve herhangi bir
        Desteklenen Blok Zinciri Ağının kesintisiz, gecikmesiz, hatasız
        çalışacağını veya işlemlerin veya mesajların bu ağda zamanında
        iletileceğini garanti etmez. Polyverse Hizmetleri, Ethereum ağı ve diğer
        EVM uyumlu ağlar dahil olmak üzere gelişmekte olan teknolojilere
        dayanır.
      </Text>

      <Text mt={4}>
        6.4 Kriptografik ve merkeziyetsiz hesaplama teknolojilerinin hızla
        gelişen bir durumda olduğunu kabul ve beyan edersiniz. Bu teknolojilerin
        dağıtımı, işlemleri ve diğer kullanımları ile ilişkili riskler tam
        olarak öngörülemeyebilir ve hızlı değişikliklere tabidir. Bu risklerin
        tamamını, bilinen veya bilinmeyen, mevcut veya gelecekte ortaya
        çıkabilecek, gönüllü olarak kabul edersiniz. Ayrıca, Polyverse ve
        iştiraklerinin, memurlarının, yöneticilerinin, çalışanlarının,
        hissedarlarının, yüklenicilerinin, avukatlarının, danışmanlarının ve
        temsilcilerinin bu tür teknolojilerin kullanımına ilişkin risklerle
        ilgili herhangi bir sorumluluk taşımayacağını açıkça kabul edersiniz.
        Blok Zinciri Varlıklarıyla ilgili işlemler ve Hizmetlerin ve Platformun
        kullanımı ile ilişkili riskleri değerlendirmenin, özelliklerini,
        potansiyel değerini, erişilebilirliğini ve uygunluğunu değerlendirmenin
        yalnızca sizin sorumluluğunuzda olduğunu kabul edersiniz. Yukarıda
        belirtilen genel hükümlere zarar vermeksizin, Polyverse Hizmetlerini
        kullanarak oluşturduğunuz veya ilişkilendirdiğiniz tokenlarınızın
        yetkisiz erişim, hırsızlık veya kaybı ile ilgili tüm riskleri
        üstlenirsiniz. Hizmetlere ve Platforma kendi riskinizle eriştiğinizi ve
        kullandığınızı kabul ve beyan edersiniz.
      </Text>

      <Text mt={4}>
        6.5 Blok Zinciri Varlıklarının fiyatının dalgalanabileceğini ve olumsuz
        finansal riskler oluşturabileceğini bilmelisiniz. Dijital Varlıkların
        fiyatı ve değeri, potansiyel gelirle birlikte, çeşitli nedenlerle
        dalgalanabilir ve bu da çıkarlarınıza karşı çalışabilir. Bir dijital
        varlık ürünü fiyatında düşüş yaşayabilir ve belirli durumlarda tüm
        değerini kaybedebilir. Blok zinciri teknolojisi, nispeten yeni ve hızla
        gelişen bir yapıda olup, Dijital Varlıkların değerini etkileyebilir.
        Dijital Varlık işlemleri geri alınamaz olabilir; dolayısıyla
        dolandırıcılık veya istem dışı işlemlerden kaynaklanan kayıplar geri
        alınamaz. Yasal düzenlemeler, piyasa manipülasyonu ve diğer beklenmeyen
        nedenler Dijital Varlıkların değerinde değişikliklere neden olabilir.
        Dijital Varlıklar hakkında karar vermeden önce profesyonel danışmanınıza
        başvurmanız tavsiye edilir.
      </Text>

      <Text mt={4}>
        6.6 Polyverse, Hizmetleri ve Platformu (tamamını veya bir kısmını)
        değiştirme, askıya alma veya sonlandırma hakkını herhangi bir bildirimde
        bulunmaksızın kendi takdirine bağlı olarak saklı tutar. Polyverse’in
        Hizmetlerin, Platformun veya herhangi bir bölümünün değiştirilmesi,
        askıya alınması veya sonlandırılması ile ilgili olarak size veya üçüncü
        bir tarafa karşı herhangi bir sorumluluğu olmayacağını kabul edersiniz.
        Polyverse, hesabınızı açmayı reddetme veya Hizmetleri veya Platformu
        kullanmanızı askıya alma hakkını tamamen kendi takdirine bağlı olarak
        saklı tutar. Polyverse, yasal gereklilikler, mahkeme kararı veya diğer
        iş gereklilikleri doğrultusunda, Kişisel Kullanım Bireylerinin ve
        Kişisel Kullanım İşletmelerinin Cüzdanlarında gerçekleştirilen işlemleri
        iptal etme yetkisine sahiptir.
      </Text>

      <Text mt={4}>
        6.7 Polyverse’in Hizmetlerle ilgili olarak destek veya bakım sağlama
        yükümlülüğü bulunmadığını kabul ve beyan edersiniz, aksi belirtilmedikçe
        ayrı bir anlaşmada açıkça belirtilmedikçe.
      </Text>

      <Text mt={4}>
        6.8 HİZMETLER "OLDUĞU GİBİ" VE "MEVCUT OLDUĞU GİBİ" SAĞLANMAKTADIR VE
        POLYVERSE (VE TEDARİKÇİLERİMİZ) HER TÜRLÜ AÇIK VEYA ZIMNİ GARANTİLERİ VE
        KOŞULLARI, SATILABİLİRLİK, BELİRLİ BİR AMACA UYGUNLUK, MÜLKİYET,
        KESİNTİSİZLİK, DOĞRULUK VEYA İHLAL EDİLMEZLİK GARANTİLERİ VE KOŞULLARI
        DAHİL OLMAK ÜZERE AÇIKÇA REDDEDER. HİZMETLERİN KESİNTİSİZ, ZAMANINDA,
        GÜVENLİ VEYA HATASIZ OLACAĞINI VEYA VİRÜS VEYA DİĞER ZARARLI
        BİLEŞENLERDEN ARINMIŞ OLACAĞINI, DOĞRU, GÜVENİLİR, EKSİKSİZ, YASAL VEYA
        GÜVENLİ OLACAĞINI GARANTİ ETMEYİZ.
      </Text>

      <Text mt={4}>
        6.9 Hizmetlerde sağladığımız bilgilerin eksiksiz, doğru ve güncel
        olmasını sağlamak için çaba gösteriyoruz. Çabalarımıza rağmen,
        Hizmetlerdeki bilgiler zaman zaman yanlış, eksik veya güncel
        olmayabilir. Hizmetlerdeki bilgilerin eksiksizliği, doğruluğu veya doğru
        olduğuna dair hiçbir beyanda bulunmuyoruz. Polyverse, sağlanan
        raporların, verilerin ve analizlerin doğruluğunu garanti etmez. Söz
        konusu bilgileri kendi riskinizle kullanırsınız.
      </Text>
      <Text mt={4}>
        6.10 Giriş bilgilerinizin gizliliğini ve güvenliğini koruma sorumluluğu
        size aittir. Hizmetlerde bu bilgiler kullanılarak gerçekleştirilen tüm
        faaliyetler sizin tarafınızdan gerçekleştirilmiş sayılacaktır ve bu
        faaliyetlerden kaynaklanan tüm hukuki ve cezai sorumluluk size aittir.
        Polyverse’e, parolanızın yetkisiz kullanımı veya başka bir güvenlik
        ihlali olduğunu fark ettiğinizde derhal bildirimde bulunmalısınız.
      </Text>
      <Text mt={4}>
        {" "}
        6.11 Polyverse, Hizmetlerdeki ve Platformdaki bilgileri ilgili kamu
        otoriteleriyle talep doğrultusunda paylaşabilir.
      </Text>
      <Text mt={4}>
        6.12 Dijital Varlıkların düzenlenmesi birçok yargı bölgesinde hala
        gelişme aşamasındadır. Devlet, federal veya uluslararası düzeyde yasal
        düzenlemeler ve düzenleyici değişiklikler veya eylemler, Blok Zinciri
        Varlıklarının kullanımını, transferini, değişimini ve değerini olumsuz
        etkileyebilir. Hizmetler ve Platform, geçerli yasaların değişmesi
        nedeniyle kullanılamaz hale gelebilir ve Hizmetlerin veya Platformun
        artık erişilebilir olmaması nedeniyle kayıplar yaşayabilir veya
        yükümlülükler altına girebilirsiniz.
      </Text>
      <Text mt={4}>
        6.13 Blok Zinciri Varlıklarının doğası, dolandırıcılık veya siber
        saldırı riskini artırabilir ve Hizmetlerin yaşadığı teknolojik
        zorluklar, Blok Zinciri Varlıklarınıza erişimi veya kullanımınızı
        engelleyebilir. Blok zinciri teknolojisi ve varlıkları ile bunların
        temelini oluşturan yazılım, üçüncü taraf siber saldırılara veya hack
        girişimlerine, kötü amaçlı yazılım saldırıları, hizmet reddi
        saldırıları, konsensüs tabanlı saldırılar, smurfing ve spoofing gibi
        saldırılara karşı savunmasız olabilir. Bu tür saldırılar, Blok Zinciri
        Varlıklarının çalınmasına veya kaybedilmesine yol açabilir.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        7. Süre ve Fesih
      </Heading>

      <Text mt={4}>
        7.1 Bu Koşullar Polyverse Hizmetlerini kullandığınız sürece geçerli
        olacaktır. Bu Koşulları ihlal eden herhangi bir kullanımı da içeren
        herhangi bir zamanda ve tamamen kendi takdirimize bağlı olarak Polyverse
        Hizmetlerine erişim ve kullanma hakkınızı askıya alma veya sonlandırma
        hakkını saklı tutarız. Bu Koşullar kapsamındaki haklarınızın sona
        ermesiyle Polyverse Hizmetlerine erişim ve kullanım haklarınız derhal
        sona erecektir. Haklarınızın sona ermesi içeriğinizin canlı
        veritabanlarımızdan kaldırılmasını içerebilir. Polyverse bu Koşullar
        kapsamındaki haklarınızın sona erdirilmesiyle ilgili olarak size karşı
        hiçbir sorumluluk kabul etmez; hesabınızın silinmesi veya bize
        sağladığınız herhangi bir ağ adresinin kara listeye alınması da dahil
        olmak üzere. Bu Koşullar kapsamındaki haklarınızın sona erdirilmesinden
        sonra bile bu Koşulların şu bölümleri geçerliliğini koruyacaktır: Bölüm
        3 (Fikri Mülkiyet), Bölüm 6 (Feragatnameler), Bölüm 8 (Gizlilik), Bölüm
        9 (Tazminat), Bölüm 10 (Sorumluluğun Sınırlandırılması), Bölüm 12
        (Uyuşmazlık Çözümü) ve Bölüm 13 (Geçerli Hukuk ve Yargı Yetkisi).
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        8. Yürürlükteki Hukuk ve Yetki Alanı
      </Heading>

      <Text mt={4}>
        8.1 Bu Koşullar ve Hizmetlerle bağlantılı olarak ortaya çıkan herhangi
        bir anlaşmazlık, dava veya iddia ("Anlaşmazlıklar"), esas itibariyle
        Kanada kanunlarına ve ilgili düzenlemelere tabi olacaktır. Bu Koşullarla
        ilgili olarak ortaya çıkan herhangi bir Anlaşmazlık, Türk kanunları ve
        düzenlemeleri çerçevesinde çözüme kavuşturulacaktır. Her iki taraf da bu
        tür Anlaşmazlıkların çözümü için Türk Mahkemelerinin münhasır yetkisini
        kabul eder.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        9. Tazminat
      </Heading>

      <Text mt={4}>
        9.1 Polyverse’i (ve memurlarını, çalışanlarını ve temsilcilerini)
        Polyverse Hizmetlerini kullanımınızdan kaynaklanan veya bunlarla
        bağlantılı herhangi bir üçüncü taraf iddiası, talebi veya eylemi
        nedeniyle ortaya çıkan tüm sorumluluklardan, kayıplardan, zararlar,
        maliyetler ve masraflardan (avukatlık ücretleri ve masraflar dahil)
        tazmin etmeyi ve zarar görmemesini sağlamayı kabul edersiniz. Polyverse
        bu tür bir iddianın savunmasını ve kontrolünü kendi masraflarıyla
        üstlenme hakkını saklı tutar ve bu durumda Polyverse’in bu tür bir
        iddianın savunmasına işbirliği yapmayı kabul edersiniz. Polyverse’in
        yazılı izni olmadan bu tür bir iddia, talep veya eylemi çözüme
        kavuşturmayacaksınız. Polyverse bu tür bir iddia, dava veya işlemi fark
        ettiği anda sizi bilgilendirmek için makul çabayı gösterecektir.
      </Text>
      <Heading as="h2" size="lg" mt={8}>
        10. Sorumluluğun Sınırlandırılması
      </Heading>

      <Text mt={4}>
        10.1 BAZI YARGI BÖLGELERİ DOLAYLI VEYA ARIZİ ZARARLAR İÇİN SORUMLULUK
        SINIRLAMALARINI VEYA HARİÇ TUTMALARINI KABUL ETMEMEKTEDİR. BU NEDENLE
        AŞAĞIDAKİ SINIRLAMA SİZİN İÇİN GEÇERLİ OLMAYABİLİR VE EK HAKLARA SAHİP
        OLABİLİRSİNİZ.
      </Text>

      <Text mt={4}>
        10.2 KANUNLARIN İZİN VERDİĞİ AZAMİ ÖLÇÜDE, POLYVERSE HİÇBİR DURUMDA
        HERHANGİ BİR KULLANICIYA VEYA ÜÇÜNCÜ TARAFI HERHANGİ BİR K R KAYBI, VERİ
        KAYBI VEYA POLYVERSE HİZMETLERİNİN KULLANIMINDAN KAYNAKLANAN VEYA
        BAĞLANTILI HERHANGİ BİR DOLAYLI, SONUÇSAL, ARIZİ, ÖZEL VEYA CEZAİ
        ZARARLAR İÇİN SORUMLU TUTULAMAZ. POLYVERSE HİZMETLERİNE ERİŞİMİNİZ VE
        KULLANIMINIZ TAMAMEN KENDİ TAKDİRİNİZDE VE RİSKİNİZDEDİR VE BU NEDENLE
        CİHAZINIZA VEYA BİLGİSAYAR SİSTEMİNİZE VEYA VERİ KAYBINA HERHANGİ BİR
        ZARAR VERMENİZDEN YALNIZCA SİZ SORUMLUSUNUZ. POLYVERSE, ÜÇÜNCÜ TARAFLAR
        TARAFINDAN POLYVERSE HİZMETLERİ ÜZERİNDEN VEYA BAĞLANTILI OLARAK SUNULAN
        YAZILIM, ÜRÜNLER, HİZMETLER VE/VEYA BİLGİLERDEN KAYNAKLANAN VEYA
        BAĞLANTILI OLAN ZARARLAR İÇİN HERHANGİ BİR DURUMDA SORUMLU TUTULAMAZ.
      </Text>
      <Text mt={4}>
        10.3 POLYVERSE, ŞİFRELERİNİZİN, ÖZEL ANAHTARLARINIZIN VEYA CÜZDANINIZA
        ERİŞİM KREDİLERİNİN GÜVENLİĞİNİ SAĞLAMAMAKTAN VEYA CÜZDANINIZLA İLGİLİ
        HERHANGİ BİR YETKİSİZ ERİŞİM VEYA İŞLEMDEN KAYNAKLANAN HERHANGİ BİR
        KAYIP VEYA ZARAR İÇİN SORUMLU DEĞİLDİR.
      </Text>
      <Text mt={4}>
        10.4 KANUNLARIN İZİN VERDİĞİ AZAMİ ÖLÇÜDE, BURADA AKSİ BELİRTİLMEDİKÇE,
        POLYVERSE'İN BU ANLAŞMA KAPSAMINDA HERHANGİ BİR NEDENLE (VE EYLEMİN
        BİÇİMİ NE OLURSA OLSUN) KAYNAKLANAN HERHANGİ BİR ZARAR İÇİN SİZE KARŞI
        AZAMİ SORUMLULUĞU HER ZAMAN ON ABD DOLARI (ABD $10) İLE
        SINIRLANDIRILACAKTIR. BİRDEN FAZLA İDDİANIN VARLIĞI BU SINIRI
        ARTIRMAYACAKTIR.
      </Text>
      <Heading as="h2" size="lg" mt={8}>
        11. Mücbir Sebep
      </Heading>
      <Text mt={4}>
        11.1 Polyverse, kontrolü dışında gerçekleşen olaylardan doğrudan veya
        dolaylı olarak kaynaklanan herhangi bir yükümlülüğün veya gecikmenin
        yerine getirilmesinde sorumlu değildir. Bu olaylar arasında kazalar,
        yangınlar, patlamalar, savaş veya terör eylemleri, sivil veya askeri
        ayaklanmalar, grevler, iş durdurmaları, ambargolar, salgınlar, nükleer
        veya doğal afetler veya mücbir sebep olayları ve hizmetler, güç kaynağı,
        internet, iletişim, bilgisayar (yazılım ve donanım) hizmetleri veya blok
        zinciri ağlarının kesintileri, kaybı veya arızaları sayılabilir.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        12. Uyuşmazlık Çözümü
      </Heading>

      <Text mt={4}>
        12.1 BAZI YARGI BÖLGELERİ ZORUNLU TAHKİM, TOPLU DAVA YASAĞI VEYA
        TÜKETİCİNİN BULUNDUĞU YERDE YASALAR VE MAHKEMELER DIŞINDA DİĞER YASAL VE
        FORUMLARI KABUL ETMEMEKTEDİR. BU YARGI BÖLGELERİNDE BULUNUYORSANIZ
        AŞAĞIDAKİ HÜKÜMLER SİZİN İÇİN GEÇERLİ OLMAYABİLİR VE EK HAKLARA SAHİP
        OLABİLİRSİNİZ.
      </Text>

      <Text mt={4}>
        12.2 Arabuluculuk: Taraflar arasında bu Koşulların uygulanması, yorumu,
        uygulanması veya geçerliliği ile ilgili herhangi bir uyuşmazlık çıkması
        durumunda Taraflar öncelikle İstanbul Tahkim Merkezi'nde arabuluculuk
        yoluyla bu uyuşmazlığı çözmeyi kabul ederler. Taraflardan herhangi biri
        önemli zararların meydana geleceğini düşündüğünde geçici bir tedbir
        talebinde bulunmaktan kaçınamaz. Uyuşmazlığa karışan taraf diğer
        taraflara arabuluculuk yoluyla belirli bir uyuşmazlığı çözme isteğini
        bildirebilir. Arabulucu taraflarca ortaklaşa atanacaktır. Ancak taraflar
        beş gün içinde arabulucu üzerinde anlaşamazsa İstanbul Tahkim Merkezi
        arabulucuyu atayacaktır. Arabuluculuk İstanbul, Türkiye'de
        gerçekleştirilecektir. Taraflar arabuluculuk yoluyla uyuşmazlıklarını
        çözmeye çalışmayı kabul ederler. Arabuluculukla ilgili masraflar
        taraflar arasında eşit olarak paylaşılacaktır. Uyuşmazlık, arabuluculuk
        niyetinin bildirilmesinden itibaren otuz gün içinde çözülmezse
        taraflardan herhangi biri arabuluculuk sürecini sonlandırabilir ve
        aşağıda belirtilen tahkim yoluna başvurabilir.
      </Text>

      <Text mt={4}>
        12.3 Tahkim: Arabuluculuk maddelerine tabi olarak taraflar arasında bu
        Anlaşmanın herhangi bir hükmünün yorumlanması veya uygulanmasıyla ilgili
        herhangi bir uyuşmazlık veya anlaşmazlık çıkması durumunda bu uyuşmazlık
        İstanbul Tahkim Merkezi'nde İstanbul Tahkim Merkezi Tahkim Kuralları
        uyarınca tahkim yoluyla çözülecektir. Tahkim dili Türkçe olacaktır.
        Uyuşmazlığın esasına uygulanacak hukuk Türk hukuku olacaktır.
        Taraflardan herhangi biri bir uyuşmazlığı tahkime başvuracağını
        bildirebilir. Tek hakem İstanbul Tahkim Merkezi Yönetim Kurulu
        tarafından atanacaktır. Tahkim işlemleri İstanbul, Türkiye'de
        gerçekleştirilecektir. Tahkim, Tahkim Kanunu hükümlerine uygun olarak
        ilerleyecektir. Hakem tarafından verilen karar nihai ve bağlayıcı olacak
        ve temyiz hakkı bulunmayacaktır. Hakem tarafından verilen karar yetkili
        herhangi bir mahkemede icra edilebilir. Tahkimle ilgili masraflar
        taraflar arasında eşit olarak paylaşılacaktır.
      </Text>
      <Heading as="h2" size="lg" mt={8}>
        13. Geçerli Hukuk ve Yargı Yetkisi
      </Heading>

      <Text mt={4}>
        13.1 Bu Koşullar ve bunların konusu veya oluşumuyla ilgili (sözleşme
        dışı uyuşmazlıklar veya iddialar dahil) ortaya çıkan herhangi bir
        uyuşmazlık veya iddia Türkiye Cumhuriyeti yasalarına tabi olacak ve bu
        yasalara göre yorumlanacaktır. İstanbul mahkemelerinin bu Koşulların
        konusu veya oluşumuyla ilgili (sözleşme dışı uyuşmazlıklar veya iddialar
        dahil) ortaya çıkan herhangi bir uyuşmazlık veya iddiayı çözme yetkisine
        sahip olduğunu kabul edersiniz.
      </Text>

      <Text mt={4}>
        13.2 Türkiye dışında bulunuyorsanız Polyverse Hizmetlerini tamamen kendi
        riskinizle kullanırsınız veya erişirsiniz. Polyverse Hizmetleri bazı
        yargı bölgelerinde uygun olmayabilir veya kullanılamayabilir. Polyverse
        ve iştirakleri Polyverse Hizmetlerinin veya herhangi bir bölümünün
        Türkiye dışındaki belirli bir yargı bölgesinde uygun veya kullanıma
        uygun olduğunu beyan etmez veya garanti etmez. Polyverse Hizmetlerine
        erişmeye ve kullanmaya kendi inisiyatifinizle ve kendi riskinizle karar
        veriyorsunuz ve tüm yerel yasa düzenleme ve kurallara uyma sorumluluğu
        size aittir.
      </Text>
      <Heading as="h2" size="lg" mt={8}>
        14. Genel
      </Heading>

      <Text mt={4}>
        14.1 Tam Koşullar: Bu Koşullar Polyverse Hizmetlerinin kullanımıyla
        ilgili olarak sizinle aramızdaki tüm anlaşmayı oluşturur. Bu Polyverse
        Kullanıcı Koşullarındaki bölüm başlıkları yalnızca kolaylık sağlamak
        amacıyla verilmiştir ve yasal veya sözleşmesel bir etkisi yoktur.
        "İçeren" terimi "sınırlama olmaksızın" anlamına gelir. Bu Koşulların
        herhangi bir hükmünün herhangi bir nedenle geçersiz veya uygulanamaz
        olduğunun tespit edilmesi durumunda geri kalan hükümler yürürlükte
        kalacak ve geçersiz veya uygulanamaz hüküm kanunların izin verdiği en
        geniş ölçüde geçerli ve uygulanabilir olacak şekilde kabul edilecektir.
        Kendi adınıza hareket ettiğinizi ve başka bir tarafın yararına hareket
        etmediğinizi burada teyit edersiniz. Polyverse ile ilişkiniz bağımsız
        bir yüklenici olarak kabul edilir ve hiçbir taraf diğerinin temsilcisi
        veya ortağı değildir. Bu Koşullar ve buradaki haklarınız ve
        yükümlülükleriniz Polyverse’in önceden yazılı izni olmaksızın sizin
        tarafınızdan devredilemez, alt sözleşme yapılamaz, yetkilendirilemez
        veya başka bir şekilde transfer edilemez. Aksi takdirde yapılan herhangi
        bir devretme, alt sözleşme, yetkilendirme veya transfer geçersiz
        sayılacaktır. Polyverse bu Koşulları serbestçe devredebilir. Bu
        Koşullarda belirtilen hüküm ve koşullar atanmışlar için bağlayıcı
        olacaktır.
      </Text>

      <Text mt={4}>
        14.2 Değişiklikler: Uygulanabilir yasalarca aksi öngörülmedikçe bu
        Koşullar zaman zaman revizyona tabi tutulabilir. Önemli değişiklikler
        yapıldığında hesabınıza bir sonraki girişinizde güncellenmiş Koşulları
        gözden geçirmeniz ve kabul etmeniz istenebilir. Ayrıca bu değişiklikler
        hakkında bilgilendirildikten sonra Polyverse Hizmetlerini kullanmaya
        devam etmeniz bu değişiklikleri kabul ettiğiniz ve bu değişikliklerin
        hüküm ve koşullarıyla bağlı olmayı kabul ettiğiniz anlamına gelecektir.
      </Text>

      <Text mt={4}>
        14.3 Feragat: Polyverse tarafından bu Koşullar kapsamındaki herhangi bir
        hak veya çözüm yolundan feragat edilmesi yalnızca yazılı olarak
        yapılırsa ve Polyverse’in yetkili temsilcisi tarafından imzalanırsa
        geçerli olacak ve yalnızca belirtilen durumlar için geçerli olacaktır.
        Bu Polyverse Koşulları kapsamındaki herhangi bir hak veya çözüm yolunu
        kullanmama veya uygulamama bu hak veya çözüm yolundan feragat edilmesi
        anlamına gelmez ve gelecekte bu hak veya çözüm yolunun kullanılmasını
        veya uygulanmasını engellemez. Herhangi bir hak veya çözüm yolunun tek
        veya kısmi kullanımı o hakkın veya başka bir hakkın veya çözüm yolunun
        daha fazla kullanılmasını veya sınırlandırılmasını engellemez.
      </Text>

      <Heading as="h2" size="lg" mt={8}>
        15. Bize Ulaşın
      </Heading>

      <Text mt={4}>
        15.1 Polyverse Hizmetleri ile ilgili tüm geri bildirim, yorum, öneri ve
        iyileştirme önerilerini ("Geri Bildirim") memnuniyetle karşılıyoruz ve
        değerlendiriyoruz. Geri Bildirim sağlayarak, Polyverse’e Geri
        Bildirimleri ve tüm türev çalışmalarını veya iyileştirmelerini, herhangi
        bir şekilde ve herhangi bir amaçla kullanma, kopyalama, değiştirme,
        türev eserler oluşturma, ticari olarak değerlendirme ve kullanma hakkını
        ve tüm ilgili fikri mülkiyet haklarını içeren münhasır olmayan, dünya
        çapında, süresiz, geri alınamaz, tamamen ödenmiş, telifsiz, alt
        lisanslanabilir ve devredilebilir bir lisans veriyorsunuz.
      </Text>

      <Text mt={4}>
        15.2 Polyverse ile tüm iletişimlerinizin elektronik olarak yapılacağını
        kabul edersiniz.
      </Text>

      <Text mt={4}>
        15.3 Bu Koşullar veya Hizmetlerle ilgili herhangi bir sorunuz varsa,
        bizimle dilediğiniz zaman iletişime geçebilirsiniz.
      </Text>
    </Box>
  );
};

export default TermsOfService;
