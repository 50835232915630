import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Flex,
  keyframes,
  Button,
} from "@chakra-ui/react";

const gradientAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
`;

const PvBanner = ({
  title,
  title2,
  header1,
  header2,
  buttonText,
  boxPadding = "24px",
  secondButtonText,
  onSecondButtonClick,
  isHeader1Gradient = false, // Yeni eklenen prop
  isHeader2Gradient = false, // Yeni eklenen prop
}) => {
  console.log("test");
  return (
    <Box
      height={{ base: "600px", lg: "800px" }}
      bg="purple.500"
      alignContent="center"
      px={{ base: 4, sm: 12, md: 16, lg: 24, xl: "150px" }}
    >
      <VStack
        spacing={{ base: 4, sm: 6, lg: 8 }}
        textAlign="center"
        color="white"
      >
        <Flex
          as="span"
          bg="rgba(66, 153, 225, 0.1)"
          color="blue.300"
          fontSize={{ base: "16px", sm: "18px", md: "20px" }}
          fontWeight="normal"
          borderRadius="16px"
          px={boxPadding}
          py="10px"
          mb="2"
        >
          {buttonText}
        </Flex>
        <VStack spacing={{ base: 2, sm: 4, lg: 5 }}>
          <Heading
            as="h1"
            fontSize={{
              base: "36px",
              sm: "40px",
              md: "48px",
              lg: "60px",
              xl: "72px",
            }}
            fontWeight="bold"
            color={isHeader1Gradient ? "transparent" : "white"}
            bgGradient={
              isHeader1Gradient
                ? "linear(to-r, white, #DB72ED, #529BF0, white)"
                : "none"
            }
            bgClip={isHeader1Gradient ? "text" : "initial"}
            backgroundSize={isHeader1Gradient ? "300% 100%" : "initial"}
            animation={
              isHeader1Gradient
                ? `${gradientAnimation} 15s linear infinite`
                : "none"
            }
          >
            {header1}
          </Heading>
          <Heading
            as="h2"
            fontSize={{
              base: "40px",
              sm: "48px",
              md: "56px",
              lg: "72px",
              xl: "80px",
            }}
            fontWeight="bold"
            color={isHeader2Gradient ? "transparent" : "white"}
            bgGradient={
              isHeader2Gradient
                ? "linear(to-r, white, #DB72ED, #529BF0, white)"
                : "none"
            }
            bgClip={isHeader2Gradient ? "text" : "initial"}
            backgroundSize={isHeader2Gradient ? "300% 100%" : "initial"}
            animation={
              isHeader2Gradient
                ? `${gradientAnimation} 15s linear infinite`
                : "none"
            }
          >
            {header2}
          </Heading>
        </VStack>
        <Box>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "28px" }}
            fontWeight="normal"
            opacity={0.5}
          >
            {title}
          </Text>
          <Text
            fontSize={{ base: "18px", md: "22px", lg: "28px" }}
            fontWeight="normal"
            opacity={0.5}
          >
            {title2}
          </Text>
        </Box>
        {secondButtonText && (
          <Button
            mt={4}
            bg="white"
            borderRadius="16px"
            fontSize={{ base: "18px", md: "22px", lg: "24px" }}
            px={{ base: "90px", md: "140px", lg: "160px" }}
            py={{ base: "28px", md: "30px", lg: "32px" }}
            variant="pvGradient"
            onClick={onSecondButtonClick}
          >
            {secondButtonText}
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default PvBanner;
