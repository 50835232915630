import React from "react";
import PvBanner from "../components/PvBanner";
import { Box } from "@chakra-ui/react";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import QAAccordion from "../components/QAAccordion";
import ContactUs from "../components/ContactUs";
import {
  PiPuzzlePieceBold,
  PiShieldCheckeredFill,
  PiSparkleBold,
} from "react-icons/pi";
import { TbReportAnalytics } from "react-icons/tb";

const features = [
  {
    title: "Akıllı Entegrasyon",
    description:
      "Gelişmiş API ile Web2 ve Web3 uygulamalarınıza hızlı ve sorunsuz yapay zeka entegrasyonu sağlayın",
    icon: PiPuzzlePieceBold,
  },
  {
    title: "Gelişmiş Güvenlik",
    description:
      "Yapay zeka ile güçlendirilmiş (AI-Empowered) güvenlik ve doğrulama çözümleriyle uygulamalarınızı koruyun",
    icon: PiShieldCheckeredFill,
  },
  {
    title: "Veri Analitiği ve Öneriler",
    description:
      "Kişiselleştirilmiş öneriler ve detaylı veri analitiği ile kullanıcı deneyimini geliştirin",
    icon: TbReportAnalytics,
  },
  {
    title: "Kişiselleştirilmiş ve Özel",
    description:
      "Her kullanıcı için özelleştirilmiş, önceden belirlenmiş senaryolar ve komutlarla AI, hızla yanıt verebilir ve özel taleplerinize uygun çözümler sunar",
    icon: PiSparkleBold,
  },
];

const qaData = [
  {
    question: "Polyverse AI nedir?",
    answer:
      "Polyverse'ün yapay zeka hizmetleri sunan ve tek bir API ile Web2 ve Web3 uygulamalarına yapay zeka entegrasyonu sağlayan bir platformdur",
  },
  {
    question: "Polyverse AI nasıl çalışır?",
    answer:
      "API aracılığıyla Deployer, Code Editor, Suggestions ve diğer araçları kullanarak uygulamalarınıza akıllı özellikler eklemenizi sağlar",
  },
];

const storageUseCases = [
  {
    title: "Geliştiriciler",
    description:
      "Web2 ve Web3 uygulamalarına AI entegrasyonu yapmak isteyen geliştiriciler için idealdir",
  },
  {
    title: "E-Ticaret ve Perakende",
    description:
      "Müşteri etkileşimlerini iyileştirmek ve satışları artırmak isteyen e-ticaret platformları için uygundur",
  },
  {
    title: "Finans ve Bankacılık",
    description:
      "Veri analizi ve güvenlik doğrulama çözümleri sunan finansal kuruluşlar için ideal bir araçtır",
  },
  {
    title: "Medya ve Eğlence",
    description:
      "İçerik önerileri ve kullanıcı etkileşimlerini artırmak isteyen medya ve eğlence sektörü için uygundur",
  },
  {
    title: "Sağlık ve Eğitim",
    description:
      "Yapay zeka destekli çözümler ile hizmet kalitesini artırmak isteyen sağlık ve eğitim sektörü için uygun bir platformdur",
  },
];

function AI() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Polyverse'ün yapay zeka hizmeti, geliştiricilere tek bir API aracılığıyla Web2 ve Web3 uygulamalarında hızlı ve etkili bir şekilde AI hizmetleri ve modellerini entegre etme imkanı sunar. Bu hizmet, uygulamalarınıza akıllı özellikler ekleyerek kullanıcı deneyimini iyileştirir"
        header1="Web2 ve Web3 için"
        header2="Akıllı Çözümler"
        buttonText="Yapay Zeka ile Güçlendirilmiş Uygulamalar"
        boxPadding="42px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <PvFeatureGrid items={features} />
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={storageUseCases}
      />
      <QAAccordion faqData={qaData} />
      <ContactUs />
    </Box>
  );
}

export default AI;
