import React from "react";
import PvBanner from "../components/PvBanner";
import { Box } from "@chakra-ui/react";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import QAAccordion from "../components/QAAccordion";
import ContactUs from "../components/ContactUs";
import {
  TbPuzzle2,
  TbDropletDollar,
  TbLockDollar,
  TbSquareKey,
  TbZoomMoney,
} from "react-icons/tb";
import { PiHandCoins, PiShieldCheckeredFill } from "react-icons/pi";
import { TiFlashOutline } from "react-icons/ti";

const features = [
  {
    title: "Merkeziyetsiz Likidite Havuzları",
    description:
      "Kripto varlıklarınızı likidite havuzlarına ekleyerek pasif gelir elde edin",
    icon: TbDropletDollar,
  },
  {
    title: "Çeşitli Yatırım İhtiyaçları",
    description:
      "Düşük riskli ve uzun vadeli yatırım fırsatlarına erişim sağlayarak varlıklarınızı değerlendirin",
    icon: PiHandCoins,
  },
  {
    title: "Staking and Yield Farming",
    description:
      "Kripto varlıklarınızı staking veya yield farming yoluyla büyütün",
    icon: TbLockDollar,
  },
  {
    title: "Merkeziyetsiz Borsalar",
    description:
      "Güvenli ve anonim bir şekilde kripto varlıklarınızı takas edin",
    icon: TbSquareKey,
  },
  {
    title: "Güvenlik ve Şeffaflık",
    description:
      "DeFi işlemleriniz, blok zinciri teknolojisi sayesinde güvenli ve şeffaf bir şekilde gerçekleştirilir",
    icon: PiShieldCheckeredFill,
  },
  {
    title: "Anlık ve Düşük Maliyetli İşlemler",
    description:
      "Aracıları ortadan kaldırarak  kullanıcıların anında ve düşük maliyetle işlem yapmasını sağlayın",
    icon: TiFlashOutline,
  },
  {
    title: "Uyumluluk (İnteroperabilite)",
    description:
      "Farklı blok zinciri ağları arasında kolay entegrasyon ile çeşitli protokoller ve uygulamalar arasında sorunsuz geçiş sağlayın",
    icon: TbPuzzle2,
  },
  {
    title: "Yenilikçi Finansal Çözümler",
    description:
      "Farklı protokol ve akıllı kontratlara kolay erişim sağlayın. Yeni finansal enstrümanlarla tanışarak finansal stratejilerinizi genişletin",
    icon: TbZoomMoney,
  },
];

const qaData = [
  {
    question: "DeFi nedir?",
    answer:
      "DeFi,  çok çeşitli ve merkeziyetsiz finansal hizmetleri aracısız bir şekilde sunan ekosistemdir",
  },
  {
    question: "Hangi DeFi hizmetlerini sunuyorsunuz?",
    answer:
      "Staking, Yield Farming, merkeziyetsiz borsa işlemleri gibi çeşitli hizmetler sunuyoruz",
  },
  {
    question: "DeFi nasıl çalışır?",
    answer:
      "DeFi, blok zinciri  teknolojisi kullanarak finansal işlemleri merkeziyetsiz hale getirir",
  },
  {
    question: "DeFi'nin avantajları nelerdir?",
    answer:
      "DeFi'nin avantajları arasında düşük işlem maliyetleri, daha hızlı işlem süreleri, finansal erişim ve katılımın artması, şeffaflık ve merkezi otoritelerin olmadığı bir sistem bulunur. Ayrıca, kullanıcılar kendi varlıklarının kontrolüne sahip olur",
  },
];

const storageUseCases = [
  {
    title: "Bireysel Yatırımcılar",
    description:
      "Kripto varlıklarını değerlendirmek isteyen bireysel yatırımcılar için idealdir",
  },
  {
    title: "Küçük ve Orta Ölçekli İşletmeler",
    description:
      "DeFi hizmetleri ile finansal yönetimi optimize etmek isteyen işletmeler için uygundur",
  },
  {
    title: "Girişim ve Start-Up'lar",
    description:
      "Yeni finansal modeller keşfetmek isteyen girişimciler için geniş imkanlar sunar",
  },
  {
    title: "Geliştiriciler ve Blok Zinciri Meraklıları",
    description:
      "DeFi ekosistemine katkıda bulunmak ve yeni çözümler geliştirmek isteyenler için harika bir platformdur",
  },
];

function Defi() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Polyverse, finansal teknoloji (FinTech) çözümlerinizde merkeziyetsiz finans (DeFi) ekosistemine kolay ve güvenli erişiminizi sağlar. Çeşitli yatırım ihtiyaçlarına, geniş bir yelpazede finansal hizmetler sunar"
        header1="Finansal Özgürlüğünüzü"
        header2="Keşfedin"
        buttonText="Merkeziyetsiz Finansal Hizmetlere Erişim"
        boxPadding="43px"
        isHeader1Gradient={true}
        isHeader2Gradient={false}
      />

      <PvFeatureGrid items={features} />
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={storageUseCases}
      />
      <QAAccordion faqData={qaData} />
      <ContactUs />
    </Box>
  );
}

export default Defi;
