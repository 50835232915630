import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Flex,
  Center,
  Image,
  Link,
} from "@chakra-ui/react";
import TwitterIcon from "../../../assets/social-icons/twitter.svg";
import MediumIcon from "../../../assets/social-icons/medium.svg";
import DiscordIcon from "../../../assets/social-icons/discord.svg";
import LinkedInIcon from "../../../assets/social-icons/linkedin.svg";
import InstagramIcon from "../../../assets/social-icons/insta.svg";

const PlatformButton = ({ icon, name, url }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef(null);

  const handleMouseMove = useCallback((e) => {
    if (!buttonRef.current) return;
    const rect = buttonRef.current.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  }, []);

  return (
    <Link
      href={url}
      isExternal
      textDecoration="none"
      _hover={{ textDecoration: "none" }}
    >
      <Center
        ref={buttonRef}
        position="relative"
        bg="#F7F7F7"
        borderRadius="20"
        p="2px"
        w={{ base: "150px", sm: "180px", md: "200px" }}
        h={{ base: "140px", sm: "170px", md: "190px" }}
        transition="all 0.3s"
        _hover={{
          transform: { base: "translateY(-20px)", md: "translateY(-28px)" },
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        }}
        cursor="pointer"
        overflow="hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseMove={isHovered ? handleMouseMove : undefined}
      >
        {isHovered && (
          <>
            <Box
              position="absolute"
              top={mousePosition.y - 50}
              left={mousePosition.x - 50}
              width="200px"
              height="200px"
              borderRadius="full"
              bg="purple.300"
              filter="blur(30px)"
              opacity={1}
              transition="0.1s"
            />
          </>
        )}
        <Box
          zIndex={2}
          bg="#F9F9F9"
          width="full"
          height="full"
          borderRadius="18"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          pt={4}
          pr={2}
          pb={2}
          pl={4}
        >
          <Text
            fontWeight="bold"
            fontSize={{ base: "20", sm: "22", md: "24", lg: "26" }}
            alignSelf="flex-start"
          >
            {name}
          </Text>
          <Image
            src={icon}
            boxSize={{ base: 16, sm: 16, md: 20, lg: 20 }}
            alignSelf="flex-end"
          />
        </Box>
      </Center>
    </Link>
  );
};

const platforms = [
  { name: "Twitter", icon: TwitterIcon, url: "https://x.com/PolyVerseLife" },
  {
    name: "Medium",
    icon: MediumIcon,
    url: "https://medium.com/@polyverse.life",
  },
  { name: "Discord", icon: DiscordIcon, url: "https://discord.gg/wA3C98CV" },
  {
    name: "LinkedIn",
    icon: LinkedInIcon,
    url: "https://www.linkedin.com/company/polyverse-life/",
  },
  {
    name: "Instagram",
    icon: InstagramIcon,
    url: "https://www.instagram.com/polyverse.life?igsh=MTljemUzbTVkcmFzMw==",
  },
];

const SocialPlatforms = () => (
  <Box
    textAlign="center"
    mt={{ base: 4, md: 6, lg: 8 }}
    mb={{ base: 12, md: 16, lg: 24 }}
    layerStyle="pvPadding"
  >
    <VStack mb={{ base: 6, md: 8, lg: 12 }} gap={{ base: 3, sm: 4, lg: 5 }}>
      <Heading
        as="h1"
        color="blue.500"
        fontSize={{ base: "24", sm: "28", lg: "30" }}
        fontWeight="semibold"
      >
        Takipte Kal
      </Heading>
      <Heading
        as="h2"
        size="md"
        fontSize={{
          base: "32px",
          sm: "40px",
          md: "48px",
          lg: "52px",
          xl: "60px",
        }}
        fontWeight="bold"
      >
        En Sevdiğiniz Sosyal Platformlarda <br /> Bizimle İletişim Halinde Kalın
      </Heading>
    </VStack>
    <Flex
      flexWrap="wrap"
      justifyContent="center"
      gap={{ base: "24px", sm: "30px", md: "40px", lg: "52px" }}
    >
      {platforms.map((platform) => (
        <PlatformButton key={platform.name} {...platform} />
      ))}
    </Flex>
  </Box>
);

export default SocialPlatforms;
