import React from "react";
import PvBanner from "../components/PvBanner";
import { Box } from "@chakra-ui/react";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import QAAccordion from "../components/QAAccordion";
import ContactUs from "../components/ContactUs";
import { RiLock2Line } from "react-icons/ri";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { TiFlashOutline } from "react-icons/ti";
import { BsIncognito } from "react-icons/bs";
import { PiArrowElbowLeftBold } from "react-icons/pi";
import { TbSquareKey, TbReportMoney } from "react-icons/tb";

const features = [
  {
    title: "Şifreleme ve Güvenlik",
    description:
      "Kriptografi ile verilerin değiştirilmesi veya silinmesini önleyin, verileri tek bir noktada toplamayan sistem ile siber saldırılardan korunun",
    icon: RiLock2Line,
  },
  {
    title: "Ölçeklenebilirlik",
    description:
      "Verileriniz arttıkça kolayca ölçeklenebilir depolama çözümleri, daha fazla işlem kapasitesi ve veri depolama alanı sağlayın",
    icon: MdOutlineSettingsBackupRestore,
  },
  {
    title: "Maliyet Etkinliği",
    description:
      "Merkeziyetsiz sistemler pahalı veri merkezlerine olan ihtiyacı minimize eder ve veri işleme iş yükünü ağa yayarak maliyetleri düşürür",
    icon: TbReportMoney,
  },
  {
    title: "Şeffaflık ve Merkeziyetsizlik",
    description:
      "Verileriniz üzerinde tam kontrol ve şeffaflık sağlayın, doğrulanabilir ve izlenebilir bir sistemde verileri saklayın",
    icon: TbSquareKey,
  },
  {
    title: "Dayanıklılık",
    description:
      "Merkezi olmayan ağlar ile verileri farklı düğümler üzerinde dağıtarak veriye erişimin sürekli olmasını sağlayın",
    icon: PiArrowElbowLeftBold,
  },
  {
    title: "Gizlilik",
    description:
      "Güçlü kriptografik algoritmalar kullanılarak şifrelenmiş verilere yalnızca yetkili kullanıcıların erişmesini sağlayın",
    icon: BsIncognito,
  },
];

const qaData = [
  {
    question: "Merkeziyesiz depolama hizmetleri nedir?",
    answer:
      "Blok zincirinde veri depolama, şeffaflık, güvenlik ve değiştirilemezlik sağlar. Veriler, kriptografi ile korunur, merkezi olmayan bir yapıda dağıtılır ve zaman damgası ile sıralanır. Ayrıca, dağıtılmış konsensüs ve akıllı kontratlar sayesinde işlemler güvenli ve otomatik bir şekilde gerçekleştirilir, veri bütünlüğü ve erişilebilirlik sağlanır",
  },
  {
    question: "Veriler nasıl korunur?",
    answer:
      "Veriler, şifreleme ile korunur. Merkeziyetsiz yapılar, verilerin tek bir noktada toplanmasını engelleyerek olası siber saldırılara karşı daha dayanıklı hale gelir. Tek bir sunucu ya da veri merkezine yapılan saldırılar, sistem genelindeki verileri tehlikeye atamaz",
  },
  {
    question: "Hangi tür veriler saklanabilir?",
    answer:
      "Blok zincirinde kişisel bilgiler, ticari belgeler, hassas veriler ve dijital varlıklar güvenli ve değiştirilemez bir şekilde saklanabilir. Bu, kimlik bilgileri, sözleşmeler, finansal veriler, NFT'ler ve akıllı kontrat kodları gibi çeşitli verileri içerir, böylece veri yönetimi ve doğruluğu sağlanır",
  },
  {
    question: "Polyverse depolama hizmetleri nasıl kullanılır?",
    answer:
      "API entegrasyonları ile kolayca verilerinizi yükleyebilir ve yönetebilirsiniz",
  },
];

const storageUseCases = [
  {
    title: "Bireysel Kullanıcılar",
    description:
      "Kişisel verilerini güvenli saklamak isteyen birey kimlik bilgilerini blok zincirinde depolayarak yalnızca belirli bilgileri (yaş doğrulama) paylaşabilir, diğer hassas verilerini güvenli bir şekilde koruyabilir",
  },
  {
    title: "Kurumsal Şirketler",
    description:
      "Hassas veri saklama ve yönetim ihtiyaçları olan bir şirket, çalışanlarının kişisel verilerini güvenli bir şekilde depolayabilir ve sadece gerekli yetkilendirilmiş departmanlarla paylaşabilir",
  },
  {
    title: "Girişim ve Start-Up'lar",
    description:
      "Yeni teknolojilerle veri güvenliği sağlamak isteyen bir start-up, müşteri kimlik bilgilerini güvenli bir şekilde saklayarak, yalnızca gerekli olan bilgilere erişim sağlayabilir",
  },
];

function Storage() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Polyverse, kullanıcılarınıza güvenli ve merkeziyetsiz veri depolama çözümleri sunar"
        title2="Veriler en üst düzeyde şifreleme ile korunur ve güvenli, doğrulanabilir, kalıcı bir şekilde yönetilir"
        header1="Verilerinizi Koruyun ve"
        header2="Erişilebilir Kılın"
        buttonText="Güvenli ve Merkeziyetsiz Depolama"
        boxPadding="50px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <PvFeatureGrid items={features} />
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={storageUseCases}
      />
      <QAAccordion faqData={qaData} />
      <ContactUs />
    </Box>
  );
}

export default Storage;
