import React from "react";
import { Box, Text, Button, VStack, Flex } from "@chakra-ui/react";

const ContactUs = () => {
  return (
    <Box
      bg="transparent"
      layerStyle="pvPadding"
      mb={{ base: 12, md: 16, lg: 24 }}
    >
      <Flex
        bg="#D9D9D980"
        borderRadius="20"
        px={{ base: 6, sm: 6, md: 8, lg: 12, xl: 16 }}
        py={{ base: 6, sm: 8, md: 12 }}
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
      >
        <>
          <VStack
            align="center"
            flex={1}
            spacing={{ base: 2, md: 4 }}
            mr={{ base: 2, md: 4 }}
          >
            <Text
              fontSize={{
                base: "20px",
                sm: "24px",
                md: "28px",
                lg: "32px",
                xl: "40px",
              }}
              fontWeight="bold"
              lineHeight="1.2"
              textAlign="center"
            >
              Polyverse ile Projelerinizi Geleceğe Taşıyın{" "}
            </Text>
            <Text
              fontSize={{
                base: "14px",
                sm: "16px",
                md: "18px",
                lg: "22px",
                xl: "24px",
              }}
              lineHeight="1.4"
              textAlign="center"
            >
              İnovasyon yolculuğunuza{" "}
              <span
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#2032EA",
                }}
              >
                bugün
              </span>{" "}
              bizimle başlayın!
            </Text>
            <Button
              as="a"
              href="/book-a-meet"
              variant="pvGradient"
              px={{ base: 4, md: 4, lg: 6, xl: 8 }}
              py={{ base: 2, md: 3 }}
              mt={{ base: 2, md: 3 }}
              fontSize={{ base: "sm", md: "md" }}
            >
              Bize Ulaşın
            </Button>
          </VStack>
          {/* <Flex w={{ md: "24%", lg: "30%" }} minW="80px" alignSelf="center">
              <Image
                src={ContactUsIcon}
                alt="Email icon"
                w="100%"
                h="auto"
                objectFit="contain"
                aspectRatio="1"
              />
            </Flex> */}
        </>
      </Flex>
    </Box>
  );
};

export default ContactUs;
