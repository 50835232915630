import React, { useState } from "react";
import { Box, Grid, Text, Link, VStack, Flex } from "@chakra-ui/react";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import {
  PiWallet,
  PiCoin,
  PiDatabase,
  PiGlobe,
  PiBrain,
  PiCaretRightBold,
} from "react-icons/pi";

const GradientIcon = ({ IconComponent, isNonHovered, ...props }) => {
  return (
    <Box
      position="relative"
      width={{ base: "40px", md: "44px", lg: "52px" }}
      height={{ base: "40px", md: "44px", lg: "52px" }}
      {...props}
    >
      <IconComponent
        style={{
          width: "100%",
          height: "100%",
          fill: isNonHovered ? "#00000099" : "url(#iconGradient)",
          stroke: isNonHovered ? "#00000099" : "url(#iconGradient)",
          transition: "all 0.3s ease",
        }}
      />
      <svg width="0" height="0">
        <defs>
          <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop stopColor="#529BF0" offset="0%" />
            <stop stopColor="#DB72EE" offset="100%" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

const GridItem = ({
  title,
  description,
  link,
  icon,
  isHovered,
  isNonHovered,
}) => (
  <Flex flexDirection="column" height="100%">
    <VStack align="start" spacing={2} flex={1}>
      <GradientIcon IconComponent={icon} isNonHovered={isNonHovered} />
      <Text
        fontSize={{ base: "18px", sm: "20px", md: "24px" }}
        fontWeight="bold"
        opacity={isNonHovered ? 0.8 : 1}
        transition="opacity 0.3s ease"
      >
        {title}
      </Text>
      <Text
        fontSize={{ base: "14px", sm: "16px" }}
        opacity={isNonHovered ? 0.7 : 0.9}
        //  transition="opacity 0.3s ease"
      >
        {description}
      </Text>
    </VStack>
    <Box position="relative" height="24px" mt={4}>
      <Link
        color={isNonHovered ? "#000000CC" : "blue.500"}
        fontWeight="bold"
        href={link}
        position="absolute"
        bottom="0"
        left="0"
        fontSize={{ base: "14px", md: "16px" }}
        //fontSize={isHovered ? "20px" : "16px"}
        //transition="all 0.3s ease"
        _hover={{
          textDecoration: "none",
        }}
        cursor="pointer"
        display="flex"
        alignItems="center"
        whiteSpace="nowrap"
      >
        Daha fazla bilgi alın
        <PiCaretRightBold style={{ marginLeft: "8px" }} size="16" />
      </Link>
    </Box>
  </Flex>
);

const ServiceGrid = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const services = [
    {
      title: "Cüzdan Servis Hizmeti (Wallet as a Service)",
      description: "Dijital cüzdan ihtiyacı için en iyi çözüm",
      link: "/wallet-as-a-service",
      icon: PiWallet,
    },
    {
      title: "Tokenizasyon",
      description: "Varlıkların dijital tokenlara dönüştürülmesi",
      link: "/tokenization",
      icon: PiCoin,
    },
    {
      title: "Merkeziyetsiz Finans (DeFi) Erişimi",
      description: "Merkeziyetsiz Finans (DeFi) hizmetlerine erişim imkanı",
      link: "/defi-access",
      icon: AiOutlineDeploymentUnit,
    },
    {
      title: "Web3 Erişimi",
      description: "Merkeziyetsiz uygulamalara ve hizmetlere erişim",
      link: "/web3-access",
      icon: PiGlobe,
    },
    {
      title: "Depolama Hizmeti",
      description: "Güvenli ve merkezi olmayan veri depolama çözümleri",
      link: "/storage",
      icon: PiDatabase,
    },
    {
      title: "Yapay Zeka",
      description: "Yapay zeka ile güçlendirilmiş uygulamalar",
      link: "/ai",
      icon: PiBrain,
    },
  ];

  return (
    <Grid
      templateColumns={{
        base: "1fr",
        md: "repeat(2, 1fr)",
        lg: "repeat(3, 1fr)",
        xl: "repeat(4, 1fr)",
      }}
      gap={{ base: 16, md: 10 }}
      layerStyle="pvPadding"
    >
      {services.map((service, index) => (
        <Box
          key={index}
          role="group"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <GridItem
            {...service}
            isHovered={hoveredIndex === index}
            isNonHovered={hoveredIndex !== null && hoveredIndex !== index}
          />
        </Box>
      ))}
    </Grid>
  );
};

export default ServiceGrid;
