import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme from "./styles/theme";
import Layout from "./layout/Layout.js";
import Home from "./pages/Home.js";
import AboutUs from "./pages/AboutUs/AboutUs.js";
import WalletAsService from "./pages/WalletAsService.js";
import Tokenization from "./pages/Tokenization.js";
import Developers from "./pages/Developers.js";
import Storage from "./pages/Storage.js";
import Defi from "./pages/Defi.js";
import AI from "./pages/AI.js";
import Web3Access from "./pages/Web3Access.js";
import BookAMeet from "./pages/BookAMeet.js";
import TermsOfService from "./docs/TermsOfService.js";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wallet-as-a-service" element={<WalletAsService />} />
            <Route path="/tokenization" element={<Tokenization />} />
            <Route path="/developers" element={<Developers />} />
            <Route path="/storage" element={<Storage />} />
            <Route path="/defi-access" element={<Defi />} />
            <Route path="/ai" element={<AI />} />
            <Route path="/web3-access" element={<Web3Access />} />
            <Route path="/book-a-meet" element={<BookAMeet />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </Layout>
      </Router>
    </ChakraProvider>
  );
}

export default App;
