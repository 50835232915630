import React from "react";
import {
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  useBreakpointValue,
} from "@chakra-ui/react";

const FeatureItem = ({ title, description, icon: Icon }) => (
  <Flex align="flex-start" height="100%">
    <Box
      bg="gray.100"
      borderRadius="full"
      width="50px"
      height="50px"
      mr={5}
      flexShrink={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {Icon && <Icon size="26px" color="black" />}
    </Box>
    <Box>
      <Text
        fontSize={{ base: "18px", sm: "20px", md: "24px" }}
        fontWeight="bold"
        mb={2}
        lineHeight={1}
      >
        {title}
      </Text>
      <Text fontSize={{ base: "14px", sm: "16px" }}>{description}</Text>
    </Box>
  </Flex>
);

const PvFeatureGrid = ({ items }) => {
  const itemCount = items.length;
  const shouldShowBorders = useBreakpointValue({ base: false, lg: true });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box layerStyle="pvPadding" pt={{ base: 8, sm: 12, lg: "72px" }}>
      <Text
        fontSize={{ base: "24", sm: "28", lg: "30" }}
        color="blue.500"
        fontWeight="semibold"
        mb={16}
        textAlign="center"
      >
        Yenilikçi Çözümlerimiz
      </Text>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        rowGap={20}
      >
        {items.map((item, index) => (
          <GridItem
            key={index}
            w={
              index === itemCount - 1 && itemCount % 3 !== 0
                ? isMobile
                  ? "100%"
                  : "120%"
                : "100%"
            }
            position="relative"
            _after={{
              content: '""',
              position: "absolute",
              right: "-5px",
              top: "50%",
              height: "140px",
              borderRight: shouldShowBorders
                ? (() => {
                    if (itemCount === 4) {
                      return index === 2 || index === 3
                        ? "none"
                        : "2px solid #D9D9D9";
                    } else if (itemCount === 3) {
                      return index === 0 || index === 1
                        ? "2px solid #D9D9D9"
                        : "none";
                    } else if (itemCount === 5) {
                      return index === 2 || index === 4
                        ? "none"
                        : "2px solid #D9D9D9";
                    } else if (itemCount === 6) {
                      return index === 2 || index === 5
                        ? "none"
                        : "2px solid #D9D9D9";
                    } else if (itemCount === 8) {
                      return index === 2 || index === 5 || index === 7
                        ? "none"
                        : "2px solid #D9D9D9";
                    }

                    return "none";
                  })()
                : "none",
              transform: "translateY(-50%)",
            }}
            pl="8"
            pr="2"
          >
            <FeatureItem {...item} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default PvFeatureGrid;
