import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import GradientCard from "./GradientCard";

const PotentialUseCases = ({ title, useCases }) => (
  <Box layerStyle="pvPadding" py={24}>
    <Text
      fontSize={{ base: "24", sm: "28", lg: "30" }}
      fontWeight="semibold"
      textAlign="center"
      mb={10}
      color="blue.500"
    >
      {title}
    </Text>
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3 }}
      spacing={8}
      alignItems="stretch"
    >
      {useCases.map((useCase, index) => (
        <GradientCard key={index} {...useCase} />
      ))}
    </SimpleGrid>
  </Box>
);

export default PotentialUseCases;
