import React from "react";
import { Box, Grid, VStack, Text, useBreakpointValue } from "@chakra-ui/react";
import Civan from "../assets/team/civan-sozkesen.png";
import Remzi from "../assets/team/remzi-binar.png";
import Caglayan from "../assets/team/caglayan-ceylan.png";
import Beyza from "../assets/team/beyza-ilhan.png";
import Ecem from "../assets/team/ecem-oguz.png";
import Erdogan from "../assets/team/erdogan-matrak.png";
import Halyna from "../assets/team/halyna.png";
import Onur from "../assets/team/onur-karagoz.png";
import Selin from "../assets/team/selin-ermis.png";
import Tugce from "../assets/team/tugce-cakir.png";
import Ali from "../assets/team/ali-ozyer.png";
import Ezgi from "../assets/team/ezgi-karagoz.png";

const HexagonImage = ({
  imageSrc,
  borderGradient = ["#DB72EE10", "#DB72E580"],
}) => {
  const size = useBreakpointValue({ base: 150, md: 280, lg: 250, xl: 300 });
  return (
    <Box position="relative" width={`${size}px`} height={`${size * 0.866}px`}>
      <svg viewBox="0 0 120 100" width="100%" height="100%">
        <defs>
          <linearGradient id="borderGradient" x1="0%" y1="100%" x2="0%" y2="0%">
            <stop offset="0%" stopColor={borderGradient[0]} />
            <stop offset="100%" stopColor={borderGradient[1]} />
          </linearGradient>
          <linearGradient id="bgGradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgba(217, 217, 217, 0.4)" />
            <stop offset="100%" stopColor="rgba(217, 217, 217, 0)" />
          </linearGradient>
          <clipPath id="hexagonClip">
            <path
              d="M38,2 
               L82,2 
               A18,20 0 0,1 94,10 
               L112,44 
               A18,18 0 0,1 112,56
               L94,90       
               A18,18 0 0,1 82,98
               L38,98
               A18,18 0 0,1 26,90
               L8,56
               A18,18 0 0,1 8,44
               L26,10
               A18,18 0 0,1 38,2"
            />
          </clipPath>
        </defs>

        {/* Background gradient */}
        <path
          d="M38,2 
           L82,2 
           A18,20 0 0,1 94,10 
           L112,44 
           A18,18 0 0,1 112,56
           L94,90       
           A18,18 0 0,1 82,98
           L38,98
           A18,18 0 0,1 26,90
           L8,56
           A18,18 0 0,1 8,44
           L26,10
           A18,18 0 0,1 38,2"
          fill="url(#bgGradient)"
        />

        {/* Image */}
        <image
          href={imageSrc}
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMid slice"
          clipPath="url(#hexagonClip)"
        />

        {/* Border */}
        <path
          d="M38,2 
           L82,2 
           A18,20 0 0,1 94,10 
           L112,44 
           A18,18 0 0,1 112,56
           L94,90       
           A18,18 0 0,1 82,98
           L38,98
           A18,18 0 0,1 26,90
           L8,56
           A18,18 0 0,1 8,44
           L26,10
           A18,18 0 0,1 38,2"
          fill="none"
          stroke="url(#borderGradient)"
          strokeWidth="0.5"
        />
      </svg>
    </Box>
  );
};

const TeamMember = ({ name, role, imageSrc }) => (
  <VStack spacing={{ base: 0, md: 2 }} align="center" h="100%">
    <HexagonImage imageSrc={imageSrc} />
    <Text
      fontWeight="bold"
      textAlign="center"
      fontSize={{ base: "18px", md: "20px", lg: "22px", xl: "26px" }}
    >
      {name}
    </Text>
    <Box
      h={{ base: "40px", md: "48px", lg: "56px" }}
      overflow="hidden"
      display="flex"
      alignItems="start"
      justifyContent="center"
    >
      <Text
        color="black"
        opacity={0.9}
        fontSize={{ base: "14px", md: "16px", lg: "18px", xl: "18px" }}
        textAlign="center"
      >
        {role}
      </Text>
    </Box>
  </VStack>
);

const TeamGrid = ({ title, members, type }) => {
  const columns = useBreakpointValue(
    type === "kurucular" ? { base: 1, sm: 1, lg: 3 } : { base: 2, sm: 2, lg: 3 }
  );

  return (
    <Box mb={16}>
      <Text
        fontSize={{ base: "24px", md: "28px", lg: "32px", xl: "36px" }}
        mb={8}
        textAlign="center"
        fontWeight="bold"
      >
        {title}
      </Text>
      <Grid
        templateColumns={`repeat(${columns}, 1fr)`}
        gap={{ base: 4, md: 6, lg: 8 }}
        justifyContent="center"
        alignItems="center"
      >
        {members.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </Grid>
    </Box>
  );
};

const Team = () => {
  const kurucular = [
    {
      name: "Civan Sözkesen",
      role: "Yatırımcı İlişkileri & İş Geliştirme",
      imageSrc: Civan,
    },
    {
      name: "Remzi Binar",
      role: "Blockchain Uzmanı & Yazılım Geliştirici",
      imageSrc: Remzi,
    },
    {
      name: "Çağlayan Ceylan",
      role: "Stratejist & Proje Yöneticisi",
      imageSrc: Caglayan,
    },
  ];

  const takim = [
    {
      name: "Erdoğan Matrak",
      role: "3D Artist & Sanat Direktörü",
      imageSrc: Erdogan,
    },
    {
      name: "Onur Karagöz",
      role: "Yazılım & Oyun Geliştirici",
      imageSrc: Onur,
    },
    { name: "Ali Özyer", role: "Topluluk Kurucu", imageSrc: Ali },
    {
      name: "Ezgi Karagöz",
      role: "3D Artist & Tasarımcı",
      imageSrc: Ezgi,
    },
    {
      name: "Beyza İlhan",
      role: "Yazılım & Uygulama Geliştirici",
      imageSrc: Beyza,
    },
    {
      name: "Tuğçe Çakır",
      role: "Topluluk Geliştirici",
      imageSrc: Tugce,
    },
    {
      name: "Halyna Prykhodniuk",
      role: "Sosyal Medya & İçerik Üreticisi",
      imageSrc: Halyna,
    },
    {
      name: "Selin Ermiş",
      role: "Yazılım Geliştirici",
      imageSrc: Selin,
    },
    {
      name: "Ecem Oğuz",
      role: "Topluluk Geliştirici",
      imageSrc: Ecem,
    },
  ];

  return (
    <Box bg="transparent" layerStyle="pvPadding">
      <Text
        pb={{ base: 2, sm: 3, md: 4, lg: 5 }}
        color="blue.500"
        textAlign="center"
        fontWeight="bold"
        fontSize={{ base: "20px", md: "24px", lg: "28px", xl: "30px" }}
      >
        Ekibimizle Tanışın
      </Text>
      <Text textStyle="pvHeader" textAlign="center">
        Geleceği Şekillendiren Ekibimiz
      </Text>
      <Text
        textAlign="center"
        pt="4"
        fontSize={{ base: "16px", md: "18px", lg: "22px", xl: "28px" }}
        fontWeight="normal"
        opacity={0.9}
        mb={8}
      >
        Takımımız, blok zincir teknolojisi, yazılım geliştirme ve kullanıcı
        deneyimi tasarımındaki uzmanlıklarıyla her projeye benzersiz bir
        perspektif getirir. Web3'ü basitleştirme ve potansiyelini herkese açma
        konusunda tutkuluyuz
      </Text>
      <TeamGrid title="Kurucular" members={kurucular} type="kurucular" />
      <TeamGrid title="Takım" members={takim} type="takim" />
    </Box>
  );
};
export default Team;
