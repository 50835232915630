import React from "react";
import { useInView } from "react-intersection-observer";
import {
  Box,
  Text,
  VStack,
  Divider,
  Image,
  Flex,
  Stack,
} from "@chakra-ui/react";
import CountUp from "react-countup";

const formatNumber = (number) => {
  if (number >= 1000000) {
    return `${Math.floor(number / 10000000)}M+`;
  } else if (number >= 1000) {
    return `${Math.floor(number / 100)}K+`;
  } else if (number >= 100) {
    return `${Math.floor(number / 100)}+`;
  } else {
    return `${number}+`;
  }
};

const StatBox = ({ number, text, inView }) => {
  const formattedNumber = formatNumber(number);

  return (
    <VStack gap="2">
      <Text
        fontSize={{ base: "24px", md: "28px", lg: "32px", xl: "36px" }}
        fontWeight="bold"
        color="blue.500"
      >
        {inView ? <CountUp start={0} end={number} duration={3} /> : "0"}
        {formattedNumber.slice(-2) !== "+" ? formattedNumber.slice(2) : "+"}
      </Text>
      <Text
        textAlign="center"
        opacity={0.9}
        fontSize={{ base: "14px", md: "16px" }}
      >
        {text}
      </Text>
    </VStack>
  );
};

const Stats = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box
      ref={ref}
      layerStyle="pvPadding"
      mt={{ base: 16, md: 20, lg: 32 }}
      mb={{ base: 12, md: 16, lg: 24 }}
    >
      <VStack>
        <Text textStyle="pvHeader" textAlign="center">
          Web3 Gücünü Tanıdığınız Kullanıcı Deneyimiyle Keşfedin
        </Text>
        <Text
          textAlign="center"
          px={0}
          fontSize={{ base: "18px", md: "22px", lg: "28px" }}
          fontWeight="normal"
          opacity={0.9}
          pb={{
            base: 8,
            sm: 12,
            md: "60px",
          }}
        >
          Web2.5 ile tanışın! Polyverse ile herkes bir varlığı tokenleştirebilir
          veya sıfırdan blok zinciri altyapısı oluşturmadan Web3'te bir şeyler
          inşa edebilir
        </Text>

        <Flex
          direction={["column", "column", "row"]}
          align={["center", "center", "stretch"]}
          justify="center"
          wrap="wrap"
        >
          <Stack
            direction={["column", "column", "row"]}
            spacing={[8, 12, 16]}
            align={["center", "center", "stretch"]}
            divider={
              <Divider
                orientation={["horizontal", "horizontal", "vertical"]}
                borderColor="gray.300"
                borderWidth="1px"
                height={{ base: 0, md: "100%" }}
                width={{ base: "100%", md: 0 }}
              />
            }
          >
            <StatBox
              number={236}
              text="Web3 projesi yaratıldı"
              inView={inView}
            />
            <StatBox
              number={364}
              text="Akıllı sözleşme basıldı"
              inView={inView}
            />
            <StatBox
              number={2644}
              text="Toplam kullanıcı/cüzdan oluşturuldu"
              inView={inView}
            />
          </Stack>
        </Flex>
      </VStack>
    </Box>
  );
};

export default Stats;
